import React from "react";
import { Fragment } from "react";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import detailPage from "../../assets/img/New-update-detail/Rectangle 140 (3).png";
import Header from "../../components/Header";
import arrow from "../../assets/img/New-update-detail/Vector (24).svg";
import arrow1 from "../../assets/img/New-update-detail/Vector (25).svg";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
function FirstPage() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
      <Header />
      <Container fluid className=" NewUpdateDetail">
        <Row className="header-content pt-5 mt-5  text-center align-center">
          <h2>News and Updates</h2>
          <p>Together... We can make a difference.</p>
          <h5>
            {" "}
            <Link
              to="/"
              onClick={scrollTop}
              style={{ textDecoration: "none", color: "white" }}
            >
              Home
            </Link>{" "}
            /{" "}
            <Link
              to="/detail"
              onClick={scrollTop}
              style={{ textDecoration: "none", color: "white" }}
            >
              News and Updates
            </Link>{" "}
          </h5>
        </Row>
      </Container>

      {/* ========================content and img==================================================== */}
      <Container fluid>
        <Container>
          <Row className="d-flex justify-content-end mt-5 pt-2">
            <Col md={8} className="">
              <img src={detailPage} className="card-img-top" alt=".." />
            </Col>
            {/* don't remove this col-4 */}
            <Col md={4}></Col>
            {/* don't remove this col-4 */}
          </Row>

          <Row className="d-flex justify-content-end mt-4  ">
            <Col md={12} className=" last-detail-page">
              <h1>
                6 Spreading Love and Peace: Making a Difference by Helping
                Those in Need{" "}
              </h1>

              <p align="justify">
                In a world often plagued by division and turmoil, there is an
                urgent need for spreading love and peace and helping those who
                are in need. By coming together and extending a helping hand, we
                can create a powerful positive impact on individuals and
                communities. This article explores the importance of spreading
                love and peace while offering practical ways to make a
                difference and contribute to a better world.{" "}
              </p>

              <h2>The Power of Love and Peace </h2>
              <p align="justify">
                Love and peace have the remarkable ability to transcend
                barriers, bring people together, and foster a sense of harmony.
                By spreading love, we can create an environment of compassion
                and understanding. Peace, on the other hand, helps to calm
                conflicts and promotes a sense of stability and cooperation.
                Together, these virtues have the potential to transform lives
                and uplift communities.{" "}
              </p>
              <h2>Understanding the Needs </h2>
              <p align="justify">
                To effectively spread love and peace, it is crucial to
                understand the needs of others. Whether it's addressing hunger,
                poverty, inequality, or social injustice, identifying the
                specific challenges faced by individuals or groups helps us
                channel our efforts towards meaningful solutions. By gaining
                insights into their circumstances, we can tailor our assistance
                to offer the most relevant and impactful support.{" "}
              </p>

              <h2>Practical Ways to Help </h2>

              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled:
              </p>

              <h3 className="">Volunteer and Donate</h3>
              <p>
                Engage with local charities and non-profit organizations,
                offering your time, skills, or financial contributions to
                support their initiatives.{" "}
              </p>
              <h3>Community Outreach</h3>
              <p align="justify">
                Organize events or campaigns focused on raising awareness,
                collecting donations, or providing essential resources to those
                in need.{" "}
              </p>
              <h3>Education and Mentorship</h3>
              <p align="justify">
                Empower individuals by sharing knowledge, mentoring, or offering
                educational opportunities that can enhance their skills and
                prospects.{" "}
              </p>
              <h3>Environmental Stewardship</h3>
              <p align="justify">
                Show love for the planet by participating in environmental
                clean-ups, promoting sustainability, or supporting eco-friendly
                initiatives.
              </p>
              <h3>Social Support</h3>
              <p align="justify">
                Be a listening ear, offer emotional support, or connect
                individuals with professional assistance when needed, promoting
                mental well-being.{" "}
              </p>
              <h3>Acts of Kindness</h3>
              <p align="justify">
                Small gestures of kindness, such as offering a helping hand to
                the elderly, assisting someone with their groceries, or
                providing a meal, can go a long way in spreading love and peace.{" "}
              </p>

              <h2>Amplifying the Message </h2>
              <p align="justify">
                To maximize the impact of our efforts, it is crucial to amplify
                the message of love, peace, and helping those in need. Utilize
                social media platforms, create online campaigns, or collaborate
                with influencers to raise awareness and inspire others to join
                the cause. By encouraging dialogue and sharing success stories,
                we can create a domino effect that encourages more individuals
                and communities to participate.{" "}
              </p>
              <p align="justify">
                Spreading love and peace while helping those in need is not just
                an act of kindness; it is a responsibility we all share as
                members of the global community. By embracing empathy,
                understanding the needs of others, and taking tangible actions,
                we can make a significant difference in the lives of individuals
                and create a world that is more compassionate, harmonious, and
                equitable. Together, let's unite to spread love and peace and
                uplift those who need it, forging a brighter future for all.{" "}
              </p>

              <h2>Creating Positive Change </h2>
              <p align="justify">
                Love and peace possess an incredible power that can transform
                individuals and communities, creating a positive impact that
                reverberates far and wide. When embraced and nurtured, love and
                peace can lead to profound changes, fostering empathy,
                understanding, and unity. In this article, we explore the
                inherent strengths of love and peace, examining how they can
                inspire and drive positive transformations in our world.{" "}
              </p>

              <h2>Building Connections and Empathy </h2>
              <p align="justify">
                Love serves as a powerful force that connects us all,
                transcending differences and fostering a sense of unity. It
                ignites empathy and compassion within us, enabling us to
                understand and relate to others on a deeper level. Acts of love,
                both big and small, have the capacity to bridge divides, heal
                wounds, and nurture meaningful relationships. By practicing love
                in our interactions, we can create an environment of care,
                acceptance, and support.{" "}
              </p>

              <h2>Cultivating Harmony and Cooperation </h2>
              <p align="justify">
                Peace is a fundamental aspiration of humanity, embodying a state
                of tranquility, harmony, and cooperation. It encourages
                dialogue, understanding, and conflict resolution, paving the way
                for sustainable relationships and collective progress. When we
                prioritize peace in our lives and interactions, we create an
                environment that nurtures growth, fosters collaboration, and
                respects the dignity and rights of all individuals.{" "}
              </p>

              <h2>Initiating Change </h2>
              <p align="justify">
                The true power of love and peace is realized when they are put
                into action. By embracing love and peace as guiding principles,
                we can initiate positive change in our immediate surroundings
                and beyond. Acts of kindness, compassion, forgiveness, and
                reconciliation have the potential to create a ripple effect,
                inspiring others to follow suit. Love and peace initiatives,
                both at individual and collective levels, bring people together,
                break down barriers, and contribute to the betterment of
                society.{" "}
              </p>

              <h2>Love and Peace on a Global Scale </h2>
              <p align="justify">
                Love and peace have global significance, transcending borders,
                and cultures. International collaborations and organizations
                dedicated to promoting love and peace play a crucial role in
                fostering understanding, resolving conflicts, and building
                bridges between nations. Global movements driven by love and
                peace have shown us that unity and solidarity can triumph over
                division, bringing about transformative changes on a larger
                scale.{" "}
              </p>

              <h2>Overcoming Challenges: Love and Peace as Catalysts</h2>
              <p align="justify">
                In a world often beset by challenges and conflicts, love and
                peace serve as catalysts for transformation. They enable us to
                navigate difficult circumstances with grace, resilience, and a
                commitment to dialogue and understanding. By embodying love and
                peace, we can break the cycle of violence, prejudice, and
                injustice, working towards a more equitable, compassionate, and
                inclusive world.{" "}
              </p>

              <h2>Inspiring Others: Becoming Agents of Change</h2>
              <p align="justify">
                Every individual has the capacity to become an agent of change
                by embracing and radiating love and peace. By leading with love,
                practicing empathy, and resolving conflicts peacefully, we
                inspire others to follow suit. Our personal stories of
                transformation and acts of love and peace become beacons of
                hope, motivating others to join the movement and contribute to
                the creation of a better future.{" "}
              </p>
            </Col>
          </Row>
        </Container>
        {/* Social-Share-media */}

        <Container>
          <Row className="d-flex how-small justify-content-between mt-4 pt-1"></Row>
        </Container>
      </Container>

      {/* Social-Share-media end */}

      {/* input section here */}

      <Container fluid className="  input-last-section ">
        <Container className="mt-5 pt-5 d-flex justify-content-center">
          <Col md={10} className=" pt-3 d-flex justify-content-center">
            <div className="col-11 ">
              <div className="  last-detail-page">
                <h4>Leave a Reply</h4>
                <p className="pt-2 leave-p">
                  Your email address will not be published. Required fields are
                  marked *
                </p>
              </div>
              <Card.Body className="Leave-Reply">
                <Form className="input-main">
                  <Row className="mb-2">
                    <Col md={6} className="mb-1">
                      <Form.Control
                        type="name"
                        className="Form-Control1"
                        placeholder="First Name"
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Control
                        type="name"
                        className="Form-Control1"
                        placeholder="Last Name"
                      />
                    </Col>

                    <Col md={12} className="mt-4">
                      <Form.Control
                        type="Email"
                        className="Form-Control1"
                        placeholder="Enter Websites"
                      />
                    </Col>
                  </Row>

                  <div className="mt-4">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      className="mb-4 Form-Controlll"
                      placeholder="Any Comment"
                    />
                  </div>

                  <div className="comment-btn">
                    <button>Post Comment</button>
                  </div>
                </Form>
              </Card.Body>
            </div>
          </Col>
        </Container>
      </Container>
      {/* input section here */}

      <Footer />
    </Fragment>
  );
}

export default FirstPage;
