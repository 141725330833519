import React from 'react'
import { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'
 import Header from '../Header'
import { Link } from "react-router-dom";

function ContactUs() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
<Header/>
<Container fluid className='header-ContactUs-pic'>
              
        </Container>

    </Fragment>
  )
}

export default ContactUs