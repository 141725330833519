import React from "react";
import { Fragment } from "react";
import { Col, Container, Row, Card, CardGroup, Button } from "react-bootstrap";
import food from "../../assets/img/Our_mission/food.png";
import Health from "../../assets/img/Our_mission/health.png";
import Education from "../../assets/img/Our_mission/eduction.png";
import Water from "../../assets/img/Our_mission/water.png";
import Final from "../../assets/img/Our_mission/final.png";
import Funding from "../../assets/img//Our_mission/diaseter.png";
import Poverty from "../../assets/img/Our_mission/causes_4-1.jpg (2).svg";
import Women from "../../assets/img/Our_mission/causes_5-1.jpg (2).svg";
import Aid from "../../assets/img/Our_mission/bonded.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import educationto from "../../assets/img/Our_mission/causes_7.jpg.png";
import waterto from "../../assets/img/Our_mission/causes_8.jpg.png";
import donation from "../../assets/img/Our_mission/causes_16.jpg.png";
import { Link } from "react-router-dom";
import Christmass from "../../assets/img/WhatsApp Image 2023-11-25 at 11.20.09 PM.jpeg";
const now = 60;
function missionCard() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
      <Container fluid className="cards-main">
        <Container>
          <CardGroup className="cards-row mt-5 pt-2">
            <Col md={3} className=" update-card ">
              <div className="donate-img2-2"></div>
              <div class="card-content pt-3 donate-btn  content-bg-color ">
                <h3> Celebrate the season with purpose!</h3>
                <p className="p-2">
                  SSF's Christmas mission radiates warmth through community
                  care. Embrace the joy of giving, join hands, and make this
                  festive season truly special for those in need.
                </p>
                <a
                  target="blank"
                  href="https://www.gofundme.com/f/kncaav-mission-christmas"
                >
                  <button className="mt-2">Give Now </button>
                </a>
                <div className="pt-4"></div>
              </div>
            </Col>
            
            <Col md={3} className=" update-card ">
              <div className="donate-img2"></div>
              <div class="card-content pt-3 donate-btn  content-bg-color ">
                <h4> Community Rebuilding</h4>
                <p className="p-2">
                  Empowering Discriminated and destroyed Christian communities
                  through support and a holistic approach to their
                  rehabilitation.
                </p>
                <Link to="/give/samsahotra">
                  <button className="mt-2">Give Now </button>
                </Link>
                <div className="pt-4"></div>
              </div>
            </Col>
            
            <Col md={3} className="col-lg-3 col-md-5">
              <Card className="card">
                <Card.Img variant="top" src={Health} alt="sam-sahotra-foundation" height={""} width={""} loading="lazy" />
                <div className="card-content pt-3 donate-btn  content-bg-color">
                  <h4>Health</h4>
                  <p className="p-2">
                    SSF Providing various strategic methods to enhance the
                    quality of life and promoting healthcare needs. Making world
                    a better place where everyone has access to healthcare
                    facilities for a fulfilling life.
                  </p>
                  <Link to="/give/samsahotra">
                    <button className="mt-2">Give Now </button>
                  </Link>
                  <div className="pt-4"></div>
                </div>
              </Card>
            </Col>
          </CardGroup>

          <CardGroup className="cards-row mt-5 pt-2">
            <Col md={3} className="col-lg-3 col-md-5">
              <Card className="card">
                <Card.Img variant="top" src={Poverty} alt="sam-sahotra-foundation" height={""} width={""} loading="lazy" />
                <div className="card-content pt-3 donate-btn  content-bg-color">
                  <h3>Poverty Eradication</h3>
                  <p>
                    SSF implementing strategies that address the root causes of
                    poverty, such as a lack of access to education, healthcare,
                    and socio-economic opportunities to live their lives with
                    dignity.Providing a range of services to help this cause.
                  </p>
                  <Link to="/give/samsahotra">
                    <button className="mt-2">Give Now </button>
                  </Link>
                  <div className="pt-4"></div>
                </div>
              </Card>
            </Col>

            <Col md={3} className="col-lg-3 col-md-5">
              <Card className="card">
                <Card.Img variant="top" src={Women} alt="sam-sahotra-foundation" height={""} width={""} loading="lazy" />
                <div className="card-content pt-3 donate-btn  content-bg-color">
                  <h3>Women Empowerment</h3>
                  <p>
                    “Speak up for those who cannot speak for themselves, for the
                    rights of all who are destitute.” Proverbs 31:8 - SSF
                    empowering women through provision of skill trainings and
                    adult education opportunities for their socio–economic
                    development.
                  </p>
                  <Link to="/give/samsahotra">
                    <button className="mt-2">Give Now </button>
                  </Link>
                  <div className="pt-4"></div>
                </div>
              </Card>
            </Col>

            <Col md={3} className="col-lg-3 col-md-5">
              <Card className="card">
                <Card.Img variant="top" src={Aid} alt="sam-sahotra-foundation" height={""} width={""} loading="lazy" />
                <div className="card-content pt-3 donate-btn content-bg-colort">
                  <h3>Bonded Labor </h3>
                  <p className="p-2">
                    Bonded Labor is a grave violation of human rights. Learn
                    about the Sam Sahotra Foundation's efforts to combat this
                    issue and support their mission to free those trapped in
                    bonded Labor.
                  </p>
                  <Link to="/give/samsahotra">
                    <button className="mt-2">Give Now </button>
                  </Link>
                  <div className="pt-4"></div>
                </div>
              </Card>
            </Col>
          </CardGroup>

          <CardGroup className="cards-row mt-5 pt-2">
            <Col md={3} className="col-lg-3 col-md-5">
              <Card className="card">
                <Card.Img variant="top" src={Water} alt="sam-sahotra-foundation" height={""} width={""} loading="lazy" />
                <div className="card-content pt-3 donate-btn  content-bg-color">
                  <h3>Clean Water </h3>
                  <p>
                    The Sam Sahotra Foundation is dedicated to providing clean
                    water to communities in need. Learn how we are making a
                    difference and how you can get involved.
                  </p>
                  <Link to="/give/samsahotra">
                    <button className="mt-2">Give Now </button>
                  </Link>
                  <div className="pt-4"></div>
                </div>
              </Card>
            </Col>

            <Col md={3} className="col-lg-3 col-md-5">
              <Card className="card">
                <Card.Img variant="top" src={Final} alt="sam-sahotra-foundation" height={""} width={""} loading="lazy"/>
                <div className="card-content pt-3 donate-btn  content-bg-color">
                  <h3>Final Expense</h3>
                  <p>
                    Sam Sahotra Foundation's comprehensive guide to final
                    expense insurance will help you understand the benefits and
                    coverage options available. Don't leave your loved ones
                    burdened with expenses - educate yourself today!
                  </p>
                  <Link to="/give/samsahotra">
                    <button className="mt-2">Give Now </button>
                  </Link>
                  <div className="pt-4"></div>
                </div>
              </Card>
            </Col>

            <Col md={3} className="col-lg-3 col-md-5">
              <Card className="card">
                <Card.Img variant="top" src={Funding} alt="sam-sahotra-foundation" height={""} width={""} loading="lazy"/>
                <div className="card-content pt-3 donate-btn  content-bg-color">
                  <h3>Disaster Management</h3>
                  <p>
                    The Sam Sahotra Foundation is dedicated to disaster
                    management and providing support to communities in times of
                    crisis. Learn more about our initiatives and how you can get
                    involved.
                  </p>
                  <Link to="/give/samsahotra">
                    <button className="mt-2">Give Now </button>
                  </Link>
                  <div className="pt-4"></div>
                </div>
              </Card>
            </Col>
          </CardGroup>
        </Container>

        {/* popular cause========================= */}

        <Container className="">
          <div className="d-flex justify-content-center">
            <div className=" col-">
              <Row>
                <Col md={8} className="popular-content pt-5 mb-4">
                  <h3 className="">Popular Causes</h3>
                  <p>
                    SSF has built a platform focused on aiding entrepreneurs,
                    startups, and companies raise capital from anyone.
                  </p>
                </Col>
                <Col md={4} className=" box-content popular-caurse-btn  ">
                  <Link
                    to="/join"
                    onClick={scrollTop}
                    style={{ textDecoration: "none" }}
                  >
                    <Button className="popular-caurse-btn">Join SSF</Button>
                  </Link>
                  <div className="pt-4"></div>
                </Col>
              </Row>
            </div>
          </div>

          <CardGroup className="cards-row mt-1 pt-2">
            <Col md={3}>
              <Card className="card">
                <Card.Img variant="top" src={educationto} alt="education-img" height={""} width={""} loading="lazy" />
                <div className="main-card-contentt">
                  <div className=" ">
                    <Link
                      to="/join"
                      onClick={scrollTop}
                      style={{ textDecoration: "none" }}
                    >
                      <button className="img-btnn mb-3 mt-3 ">Education</button>
                    </Link>
                  </div>
                  <p>
                    SSF Education program is the key that unlocks the golden
                    door to freedom to deprived communities for their better
                    future.
                  </p>
                  <div>
                    

                    <Col md={12} className=" mb-3">
                      <ProgressBar
                        className="progrress col-9 mt-2"
                        variant="SOME_NAME"
                        now={now}
                        label={`${now}%`}
                        visuallyHidden
                      />
                    </Col>
                  </div>
                </div>
              </Card>
            </Col>

            <Col md={3}>
              <Card className="card">
                <Card.Img variant="top" src={waterto} alt="sam-sahotra-foundation" height={""} width={""} loading="lazy"/>
                <div className="main-card-contentt">
                  <div className=" ">
                    <Link
                      to="/join"
                      onClick={scrollTop}
                      style={{ textDecoration: "none" }}
                    >
                      <button className="img-btnn mb-3 mt-3  ">Health</button>
                    </Link>
                  </div>
                  <p>
                    Health and well-being are fundamental rights that should be
                    accessible to all. SSF enhancing equitable healthcare access
                    for everyone.
                  </p>
                  <div>
                    <Col md={12} className=" mb-3">
                      <ProgressBar
                        className="progrress col-9 mt-2"
                        variant="SOME_NAME"
                        now={now}
                        label={`${now}%`}
                        visuallyHidden
                      />
                    </Col>
                  </div>
                </div>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="card">
                <Card.Img variant="top" src={donation} alt="sam-sahotra-foundation" height={""} width={""} loading="lazy"/>
                <div className="main-card-contentt ">
                  <div className=" ">
                    <Link
                      to="/join"
                      onClick={scrollTop}
                      style={{ textDecoration: "none" }}
                    >
                      <button className="img-btnn mb-3 mt-3 ">Food</button>
                    </Link>
                  </div>
                  <p>
                    Feeding the underprivileged ones is a compassionate act at
                    providing sustenance and support to those facing food
                    insecurity.
                  </p>
                  <div>
                  </div>

                  <Col md={12} className=" mb-3">
                    <ProgressBar
                      className="progrress col-9 mt-2"
                      variant="SOME_NAME"
                      now={now}
                      label={`${now}%`}
                      visuallyHidden
                    />
                  </Col>
                </div>
              </Card>
            </Col>
          </CardGroup>
        </Container>
        <Container className="pt-5"></Container>
      </Container>
    </Fragment>
  );
}

export default missionCard;
