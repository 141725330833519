import React from 'react'
import Ourmission from '../components/Our-Mission/ourMission'
import Cards from '../components/Our-Mission/missionCard'
import Volun from '../components/Our-Mission/volunter'
import Footer from '../components/Footer'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
function OurMission() {
  return (
    <Fragment>
      <Helmet>
        <title> Our Mission to Create Lasting Change-Sam Sahotra Foundation</title>
        <meta name='description' content="Sam Sahotra Foundation's mission to create lasting change through 
initiatives in health, clean water, labor and disaster management. Join us for positive impact" />
      </Helmet>
      <Ourmission/>
      <Cards/>
      <Volun/>
      <Footer/>
    </Fragment>
  )
}

export default OurMission