import pic1 from "../../assets/img/jer2/1.jpeg"
import pic2 from "../../assets/img/jer2/2.jpeg"
import pic3 from "../../assets/img/jer2/3.jpeg"
import pic4 from "../../assets/img/jer2/4.jpeg"
import pic5 from "../../assets/img/jer2/5.jpeg"
import pic6 from "../../assets/img/jer2/6.jpeg"
import pic7 from "../../assets/img/jer2/7.jpeg"
import pic8 from "../../assets/img/jer2/8.jpeg"
import pic9 from "../../assets/img/jer2/9.jpeg"
import pic10 from "../../assets/img/jer2/10.jpeg"
import pic11 from "../../assets/img/jer2/11.jpeg"
import pic12 from "../../assets/img/jer2/12.jpeg"
import pic13 from "../../assets/img/jer2/13.jpeg"
import pic14 from "../../assets/img/jer2/14.jpeg"
import pic15 from "../../assets/img/jer2/15.jpeg"
import pic16 from "../../assets/img/jer2/16.jpeg"
import pic17 from "../../assets/img/jer2/17.jpeg"
import pic18 from "../../assets/img/jer2/18.jpeg"
import pic19 from "../../assets/img/jer2/19.jpeg"
import pic20 from "../../assets/img/jer2/20.jpeg"
import pic21 from "../../assets/img/jer2/21.jpeg"
import pic22 from "../../assets/img/jer2/22.jpeg"
import pic23 from "../../assets/img/jer2/23.jpeg"
import pic24 from "../../assets/img/jer2/24.jpeg"

export const photos2 = [
    {
      src: pic1,
      width: 3,
      height: 2
    },
    {
        src: pic2,
      width: 3,
      height: 2
    },
    {
        src: pic3,
      width: 3,
      height:2
    },
    {
        src: pic4,
      width: 3,
      height: 2
    },
    {
        src: pic5,
        width: 3,
        height: 2
      },
      {
          src: pic6,
  
        width: 3,
        height: 2
      },
      {
          src: pic7 ,
        width: 3,
        height: 2
      },
      {
          src: pic8,
        width: 3,
        height: 2
      },
      {
        src: pic9,
      width: 3,
      height: 2
    },
    {
        src: pic10,
      width: 3,
      height: 2
    },
    {
        src: pic11,
      width: 3,
      height: 2
    },
    {
        src: pic12,
      width: 3,
      height: 2
    },
    {
        src: pic13,
      width: 3,
      height: 2
    },
    {
        src: pic14,
      width: 3,
      height: 2
    },
    {
        src: pic15,
      width: 3,
      height: 2
    },
    {
        src: pic16,
      width: 3,
      height: 2
    }, 
     {
        src: pic17,
      width: 3,
      height: 2
    },
    {
        src: pic18,
      width: 3,
      height: 2
    },
    {
        src: pic19,
      width: 3,
      height: 2
    },
    {
        src: pic20,
      width: 3,
      height: 2
    },
    {
        src: pic21,
      width: 3,
      height: 2
    },
    {
        src: pic22,
      width: 3,
      height: 2
    },
    {
        src: pic23,
      width: 3,
      height: 2
    },
    {
        src: pic24,
      width: 3,
      height: 2
    }
  ];
  