import React from "react";
import { Fragment } from "react";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import detailPage from "../../assets/img/New-update-detail/Rectangle 140 (3).png";
import Header from "../../components/Header";
import arrow from "../../assets/img/New-update-detail/Vector (24).svg";
import arrow1 from "../../assets/img/New-update-detail/Vector (25).svg";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
function FirstPage() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
      <Helmet>
        <title>SSF Guide to Local Charity: Get
 Involved & Make an Impact</title>
        <meta
          name="description"
          content="How to give back to your community through local charities. 
          Work with the Sam Sahotra Foundation to help the people to change the world."
        />
        <link rel="canonical" href="https://www.samsahotra.org/local-charity-guide/"  />
      </Helmet>
      <Header />
      <Container fluid className="NewUpdateDetail">
        <Row className="header-content pt-5 mt-5  text-center align-center">
          <h2>News and Updates</h2>
          <p>Together... We can make a difference. </p>
          <h5>
            {" "}
            <Link
              to="/"
              onClick={scrollTop}
              style={{ textDecoration: "none", color: "white" }}
            >
              Home
            </Link>{" "}
            /{" "}
            <Link
              to="/detail"
              onClick={scrollTop}
              style={{ textDecoration: "none", color: "white" }}
            >
              News and Updates
            </Link>{" "}
          </h5>
        </Row>
      </Container>

      {/* ========================content and img==================================================== */}
      <Container fluid>
        <Container>
          <Row className="d-flex justify-content-end mt-5 pt-2">
            <Col md={8} className="">
              <img src={detailPage} className="card-img-top" alt=".." />
            </Col>
            {/* don't remove this col-4 */}
            <Col md={4}></Col>
            {/* don't remove this col-4 */}
          </Row>

          <Row className="d-flex justify-content-end mt-4  ">
            <Col md={12} className=" last-detail-page">
              <h1>
                The Ultimate Guide to Getting Involved in Your Local Charity
                Scene
              </h1>

              <p align="justify">
                Are you looking for ways to give back to your community and make
                a difference? Getting involved in local charities is a great way
                to do just that. From volunteering at a local animal shelter to
                participating in a charity walk, there are many ways to get
                involved and make a positive impact. In this guide, we'll
                explore some of the different ways you can get involved in your
                local charity scene and find a cause that speaks to you.{" "}
              </p>

              <h2>Identify Your Passion and Purpose</h2>
              <p align="justify">
                The first step to getting involved in your local charity scene
                is to identify your passion and purpose. What causes are you
                most passionate about? What issues do you feel strongly about?
                Once you have a clear understanding of your values and
                interests, you can start researching local charities that align
                with your passions. Consider attending events and meetings to
                learn more about the organization and its mission. By finding a
                cause that speaks to you, you'll be more motivated to get
                involved and make a difference in your community.
              </p>
              <h2>Research Local Charities and Nonprofits</h2>
              <p align="justify">
                Before getting involved in your local charity scene, it's
                important to do your research. Look for charities and nonprofits
                that align with your values and interests. You can start by
                searching online or asking friends and family for
                recommendations. Once you've identified a few organizations,
                take the time to learn more about their mission, programs, and
                impact. Look for transparency and accountability in their
                financials and operations. You can also check their ratings and
                reviews on websites like Charity Navigator or GuideStar. By
                doing your due diligence, you can ensure that your time and
                resources are going towards a cause that you believe in and that
                is making a positive impact in your community.{" "}
              </p>

              <h2>Attending Events and volunteering opportunities </h2>

              <p>
                Attending events and volunteering are great ways to get involved
                in your local charity scene. Many charities and nonprofits host
                events throughout the year, such as fundraisers, awareness
                campaigns, and community outreach programs. These events provide
                opportunities to learn more about the organization, meet
                like-minded individuals, and make a difference in your
                community. Volunteering is another great way to get involved.
                Charities and nonprofits rely heavily on volunteers to support
                their programs and services. By volunteering, you can gain
                valuable skills, make new connections, and contribute to a cause
                that you care about. Look for volunteer opportunities on the
                organization's website or social media pages or reach out to
                them directly to inquire about how you can help.{" "}
              </p>

              <h3 className="">Donate Time, Money, or Resources</h3>
              <p>
                One of the most important ways to get involved in your local
                charity scene is to donate your time, money, or resources.
                Charities and nonprofits rely on donations to fund their
                programs and services, and every little bit helps. Consider
                making a monthly donation to a charity that aligns with your
                values or volunteering your time to help with their events or
                programs. You can also donate resources, such as clothing, food,
                or supplies, to help those in need. Donating is a great way to
                make a difference in your community and support causes that you
                care about.{" "}
              </p>
              <h3>Spread the Word and Encourage Others to Get Involved</h3>
              <p align="justify">
                Another way to get involved in your local charity scene is to
                spread the word and encourage others to get involved as well.
                Share information about local charities and their events on
                social media and invite your friends and family to join you in
                volunteering or donating. You can also organize your own
                fundraising events or awareness campaigns to raise money and
                awareness for your chosen cause. By spreading the word and
                encouraging others to get involved, you can make an even bigger
                impact in your community.{" "}
              </p>
            </Col>

           
          </Row>
        </Container>
        {/* Social-Share-media */}
        <Container>
          <Row className="d-flex how-small justify-content-between mt-4 pt-1">

          </Row>
        </Container>
      </Container>

      {/* Social-Share-media end */}

      {/* input section here */}

      <Container fluid className="  input-last-section ">
        <Container className="mt-5 pt-5 d-flex justify-content-center">
          <Col md={10} className=" pt-3 d-flex justify-content-center">
            <div className="col-11 ">
              <div className="  last-detail-page">
                <h4>Leave a Reply</h4>
                <p className="pt-2 leave-p">
                  Your email address will not be published. Required fields are
                  marked *
                </p>
              </div>
              <Card.Body className="Leave-Reply">
                <Form className="input-main">
                  <Row className="mb-2">
                    <Col md={6} className="mb-1">
                      <Form.Control
                        type="name"
                        className="Form-Control1"
                        placeholder="First Name"
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Control
                        type="name"
                        className="Form-Control1"
                        placeholder="Last Name"
                      />
                    </Col>

                    <Col md={12} className="mt-4">
                      <Form.Control
                        type="Email"
                        className="Form-Control1"
                        placeholder="Enter Websites"
                      />
                    </Col>
                  </Row>

                  <div className="mt-4">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      className="mb-4 Form-Controlll"
                      placeholder="Any Comment"
                    />
                  </div>

                  <div className="comment-btn">
                    <button>Post Comment</button>
                  </div>
                </Form>
              </Card.Body>
            </div>
          </Col>
        </Container>
      </Container>
      {/* input section here */}

      <Footer />
    </Fragment>
  );
}

export default FirstPage;
