import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

function aboutGlobian() {
  return (
    <Fragment>
      <Container fluid className=" globian-main">
        <Container className=" pt-5">
          <Row>
            <div className="non-profit ">
              <h2>
                We are an non-profit organization that supports good causes and{" "}
                <span style={{ color: "#4D7C8A" }}>positive change.</span> To
                make world a better place.
              </h2>
            </div>
          </Row>
        </Container>
        <Container className=" mt-5 mb-5 ">
          <Row className=" globian-main-content  d-flex justify-content-center">
            <Col md={3} className=" globian-content">
              <h2>Our Mission</h2>
              <p className="pt-2">
                SSF non-profit foundation has set forth a mission that
                encompasses various critical areas, such as education, health,
                poverty alleviation through skills enhancement.
              </p>
            </Col>
            <Col md={3} className=" globian-content">
              <h2>Our Vission</h2>
              <p className="pt-2">
                Working hard to making world a better place, where every
                individual has access to education, healthcare, and
                opportunities for a fulfilling life.
              </p>
            </Col>
            <Col md={3} className="globian-content">
              <h2>Our Values</h2>
              <div className=" pt-2 icon-and-content">
                <div className="blue-con "></div>
                <p>Accountability</p>
              </div>
              <div className="icon-and-content">
                <div className="blue-con "></div>
                <p>Reliability</p>
              </div>
              <div className="icon-and-content">
                <div className="blue-con "></div>
                <p>Cost-effectiveness</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>
  );
}

export default aboutGlobian;
