import React from "react";
import { Fragment } from "react";
import { Container, Row } from "react-bootstrap";
import Header from "../Header";
import { Link } from "react-router-dom";

function ourMission() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
      <link rel="canonical" href="https://www.samsahotra.org/our-mission/" />
      <Header />

      <Container fluid className=" header-mission-picc"></Container>
    </Fragment>
  );
}

export default ourMission;
