import React from 'react'
import Abouthome from '../components/About-Page/Abouthome'
import Globian from '../components/About-Page/aboutGlobian'
import OurAgency from '../components/About-Page/ourAgency'
import WhatWe from '../components/About-Page/whatWedo'
import OurTram from '../components/About-Page/ourTeam'
import Trusted from '../components/About-Page/trusted'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'
function About() {
  return (
    <div>
      <Helmet>
        <title>About Us - Making a Difference 
                  Together With SSF Foundation</title>
        <meta name='description' content="Discover the heart and mission of our charity organization.
 Learn how we're dedicated to creating positive change in our community"/>
      </Helmet>
   <Abouthome/>
   <Globian/>
   <OurAgency/>
   <WhatWe/>
   {/* <OurTram/> */}
   {/* <Trusted/> */}
   <Footer/>

    </div>
  )
}

export default About