import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Education from '../../assets/img/about-page/what-Wedo/Education.svg'
import Healty from "../../assets/img/about-page/what-Wedo/Healty.svg";
import Medical from "../../assets/img/about-page/what-Wedo/Medical.svg";
import Poor from "../../assets/img/about-page/what-Wedo/Poor.svg";

function whatWedo() {
  return (
    <Fragment>
      <Container fluid className=" what-Wedo trusted-main input-last-section">
        <Container className="">
          <div className=" what-we-do pt-5">
            <h2>What We Do</h2>
            <p>
              We are a not-for-profit organisation working to improve the lives
              of underprivileged segment of the society through education,
              health and poverty alleviation programs.
            </p>
          </div>
        </Container>
        <Container>
          <Row className="pt-4">
            <Col md={3} className=" globian-content">
              <div>
                <img src={Education} className="" alt="sam-sahotra-foundation" loading="lazy" />
              </div>
              <h2 className="mt-4">Implementing Pure Education Program</h2>
              <p className="mt-3">
                The Pure Education Program is a proven approach to education
                that can help our organization achieve its goals for better
                future of the poor children.
              </p>
            </Col>

            <Col md={3} className=" globian-content">
              <div>
                <img src={Healty} className="" alt="sam-sahotra-foundation" loading="lazy" />
              </div>
              <h2 className="mt-4">
                Food Program to meet the poor community's needs
              </h2>
              <p className="mt-3">
                With our Food Program, we're committed to ensuring that everyone
                in our community has access to healthy, nourishing meals. Get
                involved today.
              </p>
            </Col>

            <Col md={3} className=" globian-content">
              <div className="pt-3">
                <img src={Medical} className="" alt="sam-sahotra-foundation" loading="lazy" />
              </div>
              <h2 className="mt-4">Carrying out Health and Medical Needs</h2>
              <p className="mt-3">
                SSF Taking care of health and medical needs who needs for a
                happy and fulfilling life. Join us for taking care of health and
                medical needs of deserving communities.
              </p>
            </Col>

            <Col md={3} className="globian-content">
              <div>
                <img src={Poor} className="" alt="sam-sahotra-foundation" loading="lazy" />
              </div>
              <h2 className="mt-4">Effective Poverty Alleviation Program</h2>
              <p className="mt-3">
                SFF Poverty Alleviation Program aims to provide sustainable
                solutions to poverty by empowering communities. Making an impact
                in communities around the world.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>
  );
}

export default whatWedo;
