import React from "react";
import { Fragment } from "react";
import { Container, Row } from "react-bootstrap";
import Header from "../Header";
import { Link } from "react-router-dom";

function NewUpdateDetail() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
      <Header />
      <Container fluid className="NewUpdateDetail">
        {/* <Row className="header-content pt-5 mt-5  text-center align-center">
          <h2 className="">News and Updates</h2>
          <p className="">Together.. We can make a difference. </p>
          <h5>
            {" "}
            <Link
              to="/"
              onClick={scrollTop}
              style={{ textDecoration: "none", color: "white" }}
            >
              Home
            </Link>{" "}
            /{" "}
            <Link
              to="/detail"
              onClick={scrollTop}
              style={{ textDecoration: "none", color: "white" }}
            >
              News and Updates
            </Link>{" "}
          </h5>
        </Row> */}
      </Container>
    </Fragment>
  );
}

export default NewUpdateDetail;
