import pic1 from "../../assets/img/jer2/cooler-provide.jpg";
import pic2 from "../../assets/img/jer/17.jpg";
import pic3 from "../../assets/img/jer2/cooler-provide1.jpg";


export const photos2 = [
  {
    src: pic1,
    width: 3,
    height: 2,
  },
  {
    src: pic2,
    width: 3,
    height: 2,
  },
  {
    src: pic3,
    width: 3,
    height: 2,
  },
  
  ];
