import React, { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import dd from "../assets/img/newupdated/first.png";
import second from "../assets/img/newupdated/second.png";
import third from "../assets/img/newupdated/Rectangle 140 (2).png";
import pic1 from "../assets/img/TobaTek/Toba-tek-1.png";
import pic2 from "../assets/img/TobaTek/Toba-tek-2.png";
import pic3 from "../assets/img/TobaTek/Toba-tek-3.png";

import { Link } from "react-router-dom";
const Quenching = () => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const data = [pic1, pic2, pic3];
  return (
    <div>
      <div className="container pb-md-5">
        <div className="container Success mb-5">
          <h2>Drinking Water Project in Toba Tek Singh Village </h2>

          <p
            style={{
              fontSize: "22px",
              letterSpacing: "2px",
            }}
          >
            The Sam Sahotra Foundation has a mission to transform lives via
            charity giving and sustainable solutions. The Sam Sahotra Foundation
            recently stepped-into beginning a journey to serve rural community
            through granting them access to the safe drinking water in a village
            located in Central Punjab's Toba Tek Singh District, Pakistan. Join
            hands with Sam Sahotra Foundation to provide clean water and
            transform lives. Together, we can work to contribute to and bring
            about a long-term change, One Pump at a Time, to give everyone
            access to its fundamental human right to clean water.  
          </p>
          <div className="d-flex">
            <Link to="/give/samsahotra">
              <button
                className="paypal_donate mt-2"
                style={{ background: "#4D7C8A" }}
              >
                Give Now
              </button>
            </Link>

            <Link
              to="/moission-hand-pump"
              onClick={scrollTop}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <button className="paypal_donate mt-2">Read More </button>
            </Link>
          </div>
        </div>
        <Container className="pt-5 mt-4 mb-4 ">
          <Row className=" detail-content-box ">
            {data?.map((photo, i) => {
              return (
                <>
                  <Col md={4} className="">
                    <div>
                      <div>
                        <img
                          src={photo}
                          className="card-img-top mt-3"
                          height={260}
                          alt="Sam Sahotra Foundation"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Quenching;
