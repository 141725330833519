import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import { Fragment } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
// import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos1 } from "./photo1";
import { photos2 } from "./photo2";
import Val from "../../components/Our-Mission/volunter2";
import Footer from "../../components/Footer";

function Quenching() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const [currentImage2, setCurrentImage2] = useState(0);
  const [viewerIsOpen2, setViewerIsOpen2] = useState(false);

  const openLightbox2 = useCallback((event, { photo, index }) => {
    setCurrentImage2(index);
    setViewerIsOpen2(true);
  }, []);

  const closeLightbox2 = () => {
    setCurrentImage2(0);
    setViewerIsOpen2(false);
  };

  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };
  return (
    <>
      <div>
        <Header />
        <Container fluid className="header-mission-picc_jer2 ">
          {/* <Row className='header-content pt-5 mt-5  text-center align-center'>
                <h2 className=''>Our Mission</h2>
                <p className=''>Together... We can make a difference. </p>
                <h5> <Link to='/' onClick={scrollTop} style={{textDecoration:'none',color:'white'}}>Home</Link >  / <Link to='/our-mission' onClick={scrollTop} style={{textDecoration:'none',color:'white'}}>Our Mission</Link> </h5>
              </Row> */}
        </Container>
        <div className=" mt-3 mb-5">
          <div className="container Success mb-5">
            <h2 className="pb-3">
              DETERMINATION OF SSF FOR THE EDUCATIONAL UPLIFTMENT OF ORPHANS
            </h2>
            <div className="row">
              <div className="col-8">
                <h3>Empowering Orphans Through Educational Upliftment</h3>
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "21px",
                    lineHeight: "2",
                  }}
                >
                  The SSF is committed to providing orphaned children in Khand
                  village with comprehensive support and opportunities to break
                  the cycle of poverty through education and personal
                  development. Their long-term ambition is to empower these
                  children to lead fulfilling lives and contribute positively to
                  their communities and society at large.
                </p>
                <h3
                  style={{
                    paddingTop: "12px",
                  }}
                >
                  1. Expanding Reach
                </h3>
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "21px",
                    lineHeight: "2",
                  }}
                >
                  SSF aims to identify and support all deserving orphaned
                  children in the region, leaving no child behind due to lack of
                  resources.
                </p>
              </div>
              <div className="col-4">
                <Val />
              </div>
            </div>
            <h3
              style={{
                paddingTop: "12px",
              }}
            >
              2. Comprehensive Educational Support
            </h3>
            <p
              style={{
                textAlign: "justify",
                fontSize: "21px",
                lineHeight: "2",
              }}
            >
              In addition to providing basic school supplies, SSF plans to
              establish a scholarship program that supports children's
              educational journey, including tuition fees, extracurricular
              activities, and access to resources.
            </p>
            <h3
              style={{
                paddingTop: "12px",
              }}
            >
              3. Mentorship Programs
            </h3>
            <p
              style={{
                textAlign: "justify",
                fontSize: "21px",
                lineHeight: "2",
              }}
            >
              {" "}
              SSF envisions mentorship programs connecting orphaned children
              with experienced individuals for life skills and career guidance.
            </p>
            <h3
              style={{
                paddingTop: "12px",
              }}
            >
              4. Skill Development Workshops
            </h3>
            <p
              style={{
                textAlign: "justify",
                fontSize: "21px",
                lineHeight: "2",
              }}
            >
              SSF recognizes the importance of equipping children with practical
              skills to enhance their employability. They plan to organize skill
              development workshops and vocational training programs for this
              purpose.
            </p>
            <h3
              style={{
                paddingTop: "12px",
              }}
            >
              5. Nutritional Support
            </h3>
            <p
              style={{
                textAlign: "justify",
                fontSize: "21px",
                lineHeight: "2",
              }}
            >
              SSF plans to implement nutrition programs to provide healthy meals
              to support children's growth and concentration in school.
            </p>

            <h3
              style={{
                paddingTop: "12px",
              }}
            >
              6. Emotional and Psychological Support
            </h3>
            <p
              style={{
                textAlign: "justify",
                fontSize: "21px",
                lineHeight: "2",
              }}
            >
              SSF provides counselling and support services for orphaned
              children to help them cope, develop resilience, and succeed
              academically.
            </p>

            <h3
              style={{
                paddingTop: "12px",
              }}
            >
              7. Community Involvement
            </h3>
            <p
              style={{
                textAlign: "justify",
                fontSize: "21px",
                lineHeight: "2",
              }}
            >
              The SSF plans to involve the local community in efforts to support
              orphaned children and foster a sense of belonging.
            </p>
            <h3
              style={{
                paddingTop: "12px",
              }}
            >
              8. Long-Term Sustainability
            </h3>
            <p
              style={{
                textAlign: "justify",
                fontSize: "21px",
                lineHeight: "2",
              }}
            >
              The organization is dedicated to ensuring the sustainability of
              its efforts. They aim to create partnerships with local
              businesses, educational institutions, and other NGOs to secure
              ongoing support for these children.
            </p>

            <h3
              style={{
                paddingTop: "12px",
              }}
            >
              9. Monitoring and Evaluation
            </h3>
            <p
              style={{
                textAlign: "justify",
                fontSize: "21px",
                lineHeight: "2",
              }}
            >
              To effectively measure the impact of their programs, SSF will
              implement a comprehensive monitoring and evaluation system to
              regularly assess progress and outcomes.
            </p>

            <h3
              style={{
                paddingTop: "12px",
              }}
            >
              10. Advocacy and Awareness
            </h3>
            <p
              style={{
                textAlign: "justify",
                fontSize: "21px",
                lineHeight: "2",
              }}
            >
              SSF aims to support orphaned children by advocating for their
              rights, needs, and challenges in the community.
            </p>
            <h3
              style={{
                paddingTop: "12px",
              }}
            >
              SSF Activity
            </h3>
            <p
              style={{
                textAlign: "justify",
                fontSize: "21px",
                lineHeight: "2",
              }}
            >
              On the second visit to Khand village, the dedicated volunteers of
              SSF helped an orphan girl. They helped her by giving her a school
              bag, books, and a uniform, along with a scholarship for her
              further studies. They also assured the family that they would help
              their child in the upcoming year as well for her educational
              uplift.
            </p>
            <p
              style={{
                textAlign: "justify",
                fontSize: "21px",
                lineHeight: "2",
              }}
            >
              SSF dedicated team under the guidance of Leader Samson John
              Sahotra committed to serving such needy orphan kids in their
              educational development. The SSF (Supporting Orphaned Children
              Foundation) is deeply committed to improving the educational
              prospects and overall well-being of orphaned children in Khand
              village and beyond. Their mission goes beyond providing immediate
              assistance; they have a long-term vision for the future of these
              children's education and development.
            </p>
          </div>
        </div>
                         
        {/* // FIRST  GALLERY */}
        <Container>
        <div>

<div className="gallery">
  {photos2.map((photo) => (
    <div key={photo.id} className="photo">
      <img
        src={photo.src}
        alt={photo.title}
        onClick={() => handlePhotoClick(photo)}
      />
    </div>
  ))}
</div>
{selectedPhoto && (
  <div className="selected-photo">
    <h2>{selectedPhoto.title}</h2>
    <img src={selectedPhoto.src} alt={selectedPhoto.title} />
  </div>
)}
</div>
        </Container>

       
      </div>
      <Footer />
    </>
  );
}

export default Quenching;
