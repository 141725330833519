import pic1 from "../../assets/img/jer2/sam-foundation-school-bags-charity.jpg";
import pic2 from "../../assets/img/jer2/sam-sahotra-foundation.jpg";
import pic3 from "../../assets/img/jer2/sff-school-book-stationary-charity.jpg";
import pic4 from "../../assets/img/jer2/sff-school-stationary-charity.jpg";


export const photos2 = [
  {
    src: pic1,
    width: 3,
    height: 2,
  },
  {
    src: pic2,
    width: 3,
    height: 2,
  },
  {
    src: pic3,
    width: 3,
    height: 2,
  },
  {
    src: pic4,
    width: 3,
    height: 2,
  },
  ];
