import React from "react";

import { Routes, Route } from "react-router-dom";
import Home from "../containers/Home.js";
import Mission from "../containers/OurMission.js";
import About from "../containers/About.js";
import ContactUs from "../containers/Contect.js";
import JoinSsf from "../containers/Join.js";
import DetailUpdate from "../containers/DetailUpdate.js";
import Nurturing from "../containers/DetailPages/nurturing-healthy-lifestyles.js";
import SecondPage from "../containers/DetailPages/SecondDetailPage.js";
import ThirdPage from "../containers/DetailPages/ThirdDetailPage.js";
import FourthPage from "../containers/DetailPages/FourthDetailPage.js";
import FifthPage from "../containers/DetailPages/FifthDetailPage.js";
import SixthPage from "../containers/DetailPages/SixthDetailPage.js";
import Jaranwala from "../containers/Jaranwala/Jaranwala.js";
import Quenching from "../containers/Quenching/Quenching.js";
import Orphanedu from "../containers/Orphanedu/Orphanedu.js";
import Give from "../containers/Give/Give.js";
import MXmass from "../containers/MXmass/MessionaXmass.js";
import MissionTTS from "../containers/MissionTobaTek/TobaTek.js";
const index = () => {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/our-mission" element={<Mission />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/join" element={<JoinSsf />} />
        <Route path="/news-and-update" element={<DetailUpdate />} />
        <Route path="/5-nurturing-healthy-lifestyles" element={<Nurturing />} />
        <Route path="/news-and-update-jaranwala" element={<Jaranwala />} />
        <Route path="/quenching" element={<Quenching />} />
        <Route path="/Orphanedu" element={<Orphanedu />} />

        <Route
          path="/get-involved-and-make-a-difference"
          element={<SecondPage />}
        />
        <Route path="/local-charity-guide" element={<ThirdPage />} />
        <Route path="/fourth" element={<FourthPage />} />
        <Route path="/fifth" element={<FifthPage />} />
        <Route path="/sixth" element={<SixthPage />} />
        <Route path="/give/samsahotra" element={<Give />} />
        <Route path="/mission/christmas" element={<MXmass />} />
        <Route path="/moission-hand-pump" element={<MissionTTS />} />
      </Routes>
    </div>
  );
};

export default index;
