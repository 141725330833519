import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import { Fragment } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
// import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos1 } from "./photo1";
import { photos2 } from "./photo2";
import Val from "../../components/Our-Mission/volunter2";
import Footer from "../../components/Footer";

function Quenching() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const [currentImage2, setCurrentImage2] = useState(0);
  const [viewerIsOpen2, setViewerIsOpen2] = useState(false);

  const openLightbox2 = useCallback((event, { photo, index }) => {
    setCurrentImage2(index);
    setViewerIsOpen2(true);
  }, []);

  const closeLightbox2 = () => {
    setCurrentImage2(0);
    setViewerIsOpen2(false);
  };
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };
  return (
    <>
      <div>
        <Header />
        <Container fluid className="header-mission-picc_jer4 "></Container>
        <div className="mt-3 mb-5">
          <div className="container Success mb-5">
            <h2 className="pb-3">
              The Sam Sahotra Foundation's Compassionate Mission in Toba Tek
              Singh Village{" "}
            </h2>
            <div className="row">
              <div className="col-8">
                <p
                  style={{
                    fontSize: "23px",
                    textAlign: "justify",
                    lineHeight: "40px",
                  }}
                >
                  Toba Tek Singh village near the surroundings of Lahore has
                  been dealing with a lot of poverty and not having enough
                  necessities like groceries. But there's a bright spot in all
                  of this – the Sam Sahotra Foundation (SSF), a team that wants
                  to make life better for struggling people. SSF is a non-profit
                  organization that has set forth a mission that encompasses
                  various critical areas, such as education, health, poverty
                  alleviation through skills enhancement.
                </p>
              </div>
              <div className="col-4">
                <Val />
              </div>
            </div>

            <p
              className="mt-4"
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
              }}
            >
              The SSF team recently went to Toba Tek Singh Village to help and
              show that they care about the struggles the people in the village
              face. It wasn't just about giving them food; it was also about
              coming together, understanding each other, and showing how much a
              little bit of kindness can change things.
            </p>

            <p
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
              }}
            >
              When the Sam Sahotra Foundation team came to Toba Tek Singh
              Village, they brought more than food. They have a promise of a
              better future. They provided boxes filled with groceries like
              sugar, flour, rice, cooking oil, and more. These were not just
              regular supplies; they became a symbol of hope for those who
              needed it.
            </p>

            <p
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
              }}
            >
              Villagers came to witness the generosity taking place. This event
              united the community, serving as a reminder that positive things
              can happen when people collaborate and work together. The
              groceries given out by the SSF team became symbols of hope for the
              people of Toba Tek Singh Village. The SSF team didn't stop at one
              event; they inspired others to help, too. This kindness event
              started a chain reaction, encouraging people to care for each
              other. This charity event in Toba Tek Singh Village shows us that
              kindness has no limits. It proves that even when things are tough,
              coming together to help others can make a real and lasting
              difference.
            </p>
            <p
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
              }}
            >
              The recent mission by the Sam Sahotra Foundation in Toba Tek Singh
              Village is a powerful example of how compassion and working
              together can transform lives. Beyond just giving out groceries,
              the event showed hope, unity, and the potential for positive
              change. The SSF team's commitment to helping communities is a
              shining example of how small acts of kindness can create a lasting
              impact
            </p>
          </div>
        </div>

        {/* // FIRST GALLERY */}
        <Container>
          <div>
            <div className="gallery">
              {photos2.map((photo) => (
                <div key={photo.id} className="photo">
                  <img
                    src={photo.src}
                    alt={photo.title}
                    onClick={() => handlePhotoClick(photo)}
                  />
                </div>
              ))}
            </div>
            {selectedPhoto && (
              <div className="selected-photo">
                <h2>{selectedPhoto.title}</h2>
                <img src={selectedPhoto.src} alt={selectedPhoto.title} />
              </div>
            )}
          </div>
        </Container>
      </div>

      <Footer />
    </>
  );
}

export default Quenching;
