import React, { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import dd from "../assets/img/newupdated/first.png";
import second from "../assets/img/newupdated/second.png";
import third from "../assets/img/newupdated/Rectangle 140 (2).png";
import pic1 from "../assets/img/jer/interview.jpeg";
import pic2 from "../assets/img/jer/wallbreak.jpeg";
import pic3 from "../assets/img/jer/WhatsApp Image 2023-08-20 at 5.30.40 AM (1).jpeg";
import pic4 from "../assets/img/jer/WhatsApp Image 2023-08-20 at 5.30.40 AM.jpeg";
import pic5 from "../assets/img/jer/WhatsApp Image 2023-08-20 at 5.31.03 AM.jpeg";
import pic6 from "../assets/img/jer/WhatsApp Image 2023-08-20 at 5.30.50 AM.jpeg";
import Quenching from "./Quenching";
import OrphanEdu from "./Orphanedu";
import MissionXmass from "./MissionXmass";
import HandPump from "./TobaTekTTS";
import { Link } from "react-router-dom";
function jerwalanew() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const data = [pic1, pic2, pic3, pic4, pic5, pic6];

  return (
    <Fragment>
      <div className=" update-main">
        {/* Quenching */}
        <HandPump />
        <MissionXmass />
        <Quenching />
        <OrphanEdu />
        
        <div
          style={{ backgroundColor: "#f5f5f5" }}
          className="update-main pb-4"
        >
          <Container>
            <div className="container Success mb-5">
              <h2>Jaranwala Incident</h2>

              <p
                style={{
                  fontSize: "22px",
                  letterSpacing: "2px",
                  
                }}
              >
                Muslims in eastern Pakistan went on a rampage Wednesday over
                allegations that a Christian man had desecrated the Quran,
                demolishing the man’s house, burning churches and damaging
                several other homes, police and local Christians said. There
                were no immediate reports of casualties. The scale of the
                violence prompted the government to deploy additional police
                forces and send in the army to help restore order.
              </p>
              <div className="d-flex">
                <Link to="/give/samsahotra">
                  <button
                    className="paypal_donate mt-2"
                    style={{ background: "#4D7C8A" }}
                  >
                    Give Now
                  </button>
                </Link>

                <Link
                  to="/news-and-update-jaranwala"
                  onClick={scrollTop}
                  style={{ textDecoration: "none" }}
                >
                  <button className="paypal_donate mt-2">Read More </button>
                </Link>
              </div>
            </div>
          </Container>

          <Container className="pt-5 mt-4 mb-4">
            <Row className="detail-content-box">
              {data?.map((photo, i) => (
                <Col xs={12} md={4} key={i}>
                  <div>
                    <div>
                      <img
                        src={photo}
                        className="card-img-top mt-3"
                        height={260}
                        alt={`Image ${i}`}
                        loading="lazy"
                        width={""}
                      />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    </Fragment>
  );
}

export default jerwalanew;
