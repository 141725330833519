import React from 'react'
import { Helmet } from "react-helmet";
import { Container } from 'react-bootstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PaymentMethod from "./PaymentMethod"

const Give = () => {
  return (
    <div>
              <Helmet>
        <title>Together we Make a Difference</title>
       
        <link rel="canonical" href="https://www.samsahotra.org/" />
        <meta
          name="description"
          content="Join Sam Sahotra's mission for education, healthcare, and poverty relief. 
          Together, we make a global impact through philanthropy."
        />
      </Helmet>
    <Header/>
    {/* <Container fluid className='join-Home2 pt-5'>

    </Container> */}
    <PaymentMethod/>
    <Footer/>

    </div>
  )
}

export default Give