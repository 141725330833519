import pic1 from "../../assets/img/TobaTek/Toba-tek-1.png";
import pic2 from "../../assets/img/TobaTek/Toba-tek-2.png";
import pic3 from "../../assets/img/TobaTek/Toba-tek-3.png";
import pic4 from "../../assets/img/TobaTek/Toba-tek-4.png";
import pic5 from "../../assets/img/TobaTek/Toba-tek-5.png";

export const photos2 = [
  {
    src: pic1,
    width: 3,
    height: 2,
  },
  {
    src: pic2,
    width: 3,
    height: 2,
  },
  {
    src: pic3,
    width: 3,
    height: 2,
  },
  {
    src: pic4,
    width: 3,
    height: 2,
  },
  {
    src: pic5,
    width: 3,
    height: 2,
  },
];
