import React from "react";
import { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import donor from "../../assets/img/Our_mission/donor.svg";
import { Link } from "react-router-dom";

function volunter() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
      <div className=" mt-2">
        <Container className="fluid">
          <Row className=" ">
            <Col md={3} className=" box-bg-color box-content ">
              <Link to="/give/samsahotra" style={{ textDecoration: "none" }}>
                <Row className=" box-content text-center">
                  <div className="donor "></div>
                  <h4>Give Now !</h4>
                  <p style={{ textAlign: "center" }}>Support Jaranwala </p>

                  <Button>Give Now </Button>
                </Row>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}

export default volunter;
