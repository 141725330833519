import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF ,FaInstagram  } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";



const Footer = () => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
      <div className="container-fluid footer-main-pic pt-5">
        <div className="container footer  text-center mt-5 ">
          <p style={{ marginLeft: "15px" }}>
            Education is One Thing no One Can Take Away From You!
          </p>

          <div className="col-12 input-content"></div>

          <ul>
            <Link
              className="text-decoration cc"
              style={{ textDecoration: "none" }}
              to="/"
              onClick={scrollTop}
            >
              <li>Home</li>
            </Link>
            <Link
              className="text-decoration cc"
              style={{ textDecoration: "none" }}
              to="/our-mission"
              onClick={scrollTop}
            >
              <li>Our Mission</li>
            </Link>
            <Link
              className="text-decoration cc"
              style={{ textDecoration: "none" }}
              to="/about-us"
              onClick={scrollTop}
            >
              <li>About Us</li>
            </Link>
            <Link
              className="text-decoration cc"
              style={{ textDecoration: "none" }}
              to="/contact"
              onClick={scrollTop}
            >
              <li>Contact Us</li>
            </Link>
          </ul>
          <h5 style={{ marginLeft: "17px" }}>
            Sam Sahotra Foundation{" "}
            <a href="/" style={{ textDecoration: "none", color: "#1B4079" }}>
              is a 501(c)(3)
            </a>{" "}
            tax-exempt charity.
          </h5>
          <h5>
            Copyright © 2023{" "}
            <a href="/" style={{ textDecoration: "none", color: "#1B4079" }}>
              Sam Sahotra Foundation{" "}
            </a>{" "}
            .All rights reserved.
          </h5>
          <h5>
            Designed and Developed By:
            <a
              href="https://geniusfixers.com/"
              target="blank"
              style={{ textDecoration: "none", color: "#1B4079" }}
            >
              {" "}
              Genius Fixers LLC
            </a>
          </h5>

          <div className="">
            <div className="row media">
              <a
                href="https://www.facebook.com/samsahotrafoundationofficial/"
                target="blank"
                class="facebook"
              >
                <FaFacebookF className="text-light h4"/>

                {/* <i class="fa-brands fa-facebook-f"></i> */}
              </a>
              {/* <a href="" class="twitter">
                <i class="fa-brands fa-twitter"></i>
              </a> */}
              <a
                href="https://www.instagram.com/samsahotrafoundation"
                class="instagram"
                target="blank"
              >
                {/* <i class="fa-brands fa-instagram"></i> */}
                <FaInstagram className="text-light h4"/>
              </a>
              <a
                href="https://www.youtube.com/@samsahotrafoundation"
                className="youtube"
                target="blank"
              >
              {/* <i class="fa-brands fa-youtube"></i> */}
              <IoLogoYoutube className="text-light h4" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
