import React from 'react'
import { Fragment } from 'react'

function map() {

    const address = encodeURIComponent('9300 Forest Point Cir Suite 165, Manassas, VA 20110, United States');
    const mapUrl = `https://maps.google.com/maps?q=${address}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
  
   

  return (
    <Fragment>
<div>
<iframe
        title="Map"
        width="100%"
        height="450"
        frameBorder="0"
       
        src={mapUrl}
        allowFullScreen
></iframe>
</div>

    </Fragment>
  )
}

export default map