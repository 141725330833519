import React from 'react'
import { Fragment } from 'react'
import { Container, Row,Col,Form,Card, Button } from 'react-bootstrap'
import detailPage from '../../assets/img/New-update-detail/Rectangle 140 (3).png'
import Header from '../../components/Header'
import arrow from '../../assets/img/New-update-detail/Vector (24).svg'
import arrow1 from '../../assets/img/New-update-detail/Vector (25).svg'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer'
import { Helmet } from 'react-helmet'
function FirstPage() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>

<Helmet>
        <title>Ways to Make a Difference:5 
Ideas for Action with SSF </title>
        <meta name='description' content="Discover 5 impactful ways to make 
a difference in the world, from volunteering and donating to community involvement and raising awareness on important issues" />
        <link rel="canonical" href="https://www.samsahotra.org/get-involved-and-make-a-difference/"  />
      </Helmet>
    <Header/>
    <Container fluid className=' NewUpdateDetail'>
                  <Row className='header-content pt-5 mt-5  text-center align-center'>
                    <h2>News and Updates</h2>
                    <p>Together... We can make a difference. </p>
                    <h5> <Link to='/' onClick={scrollTop} style={{textDecoration:'none',color:'white'}}>Home</Link >  / <Link to='/detail' onClick={scrollTop} style={{textDecoration:'none',color:'white'}}>News and Updates</Link> </h5>

                  </Row>
            </Container>

{/* ========================content and img==================================================== */}
    <Container fluid>
      
   <Container>
       <Row className='d-flex justify-content-end mt-5 pt-2'>
        <Col md={8} className="">

            <img src={detailPage} className='card-img-top' alt='..' />

        </Col>
        {/* don't remove this col-4 */}
        <Col md={4}></Col>
        {/* don't remove this col-4 */}
      
      

      
       </Row>

       <Row className='d-flex justify-content-end mt-4  '>
      
      <Col md={12} className=' last-detail-page' >
        <h1>Ways to Get Involved and Make a Difference in the World</h1>

        <p align="justify">If you're looking to make a difference in the world, there are many ways to get involved and make a positive impact. From volunteering your time to donating to a cause, there are countless opportunities to make a difference in your community and beyond. Here are 10 ideas to get you started on your journey to making a difference. </p>


        <h2>Volunteer your time and skills</h2>
        <p align="justify">One of the most impactful ways to make a difference in the world is by volunteering your time and skills. Whether it's at a local nonprofit organization, a community event, or a global humanitarian effort, your time and expertise can make a significant impact. Consider volunteering at a food bank, animal shelter, or hospital. Or, if you have a specific skill set, such as graphic design or web development, offer your services to a nonprofit organization in need. Volunteering not only helps others, but it can also be a rewarding and fulfilling experience for you.</p>
        <h2>Donate to a cause you care about</h2>
        <p align="justify">Another way to make a difference in the world is by donating to a cause you care about. Whether it's a local charity, a national organization, or a global humanitarian effort, your financial support can make a significant impact. Consider researching organizations that align with your values and beliefs and donate that fit within your budget. You can also consider setting up a recurring donation to provide ongoing support. Donating not only helps others, but it can also give you a sense of purpose and fulfillment knowing that you are making a difference in the world.</p>

        <h2 >Participate in community events and initiatives</h2>
        
        <p>One of the best ways to get involved and make a difference in the world is by participating in community events and initiatives. This could include volunteering at a local food bank, participating in a neighborhood clean-up, or attending a community meeting to voice your opinions and concerns. By getting involved in your community, you can help create positive change and build stronger connections with those around you. Plus, it's a great way to meet new people and make new friends who share your passion for making a difference.</p>



        <h3 className=''>Educate yourself and others on important issues</h3>
        <p>One of the most important ways to make a difference in the world is by educating yourself and others on important issues. This could include attending workshops or seminars on topics like climate change, social justice, or human rights. You can also share articles and resources on social media or start conversations with friends and family about issues that matter to you. By staying informed and spreading awareness, you can help create a more informed and engaged society that is better equipped to tackle the challenges we face.</p>
        <h3>Support local businesses and organizations</h3>
        <p align="justify">One way to make a difference in your community is by supporting local businesses and organizations. This could include shopping at locally owned stores, eating at locally owned restaurants, or attending events hosted by local organizations. By doing so, you are not only supporting the local economy, but also helping to build a stronger sense of community. You can also volunteer your time or donate money to local non-profits or charities that align with your values and interests. By supporting local businesses and organizations, you are helping to create a more vibrant and thriving community.</p>
        
      </Col>

      
       </Row>
      

   </Container>
  
 


   {/* Social-Share-media */}
<Container>
  <Row className='d-flex how-small justify-content-between mt-4 pt-1'>
 




  </Row>
</Container>
</Container>


   {/* Social-Share-media end */}

   {/* input section here */}

   <Container fluid className='  input-last-section '>
       <Container className='mt-5 pt-5 d-flex justify-content-center'>

    
       <Col md={10}className=' pt-3 d-flex justify-content-center'>


       <div className="col-11 ">
       <div className='  last-detail-page'>

<h4 >Leave a Reply</h4>
<p className='pt-2 leave-p'>Your email address will not be published. Required fields are marked *</p>
</div>
            <Card.Body className='Leave-Reply'>
              <Form className='input-main'>
                <Row className="mb-2">
                  <Col md={6} className='mb-1'>
                    <Form.Control type="name" className='Form-Control1' placeholder='First Name'/>
                  </Col>
                  <Col md={6}>
                    <Form.Control type="name" className='Form-Control1' placeholder='Last Name'/>
                  </Col>

                  <Col md={12} className='mt-4'>
                    <Form.Control type="Email" className='Form-Control1' placeholder='Enter Websites'/>
                  </Col>
                

                </Row>

          
                 <div className='mt-4'>

                <Form.Control as="textarea" rows={4} className="mb-4 Form-Controlll" placeholder='Any Comment' />
                 </div>

                  <div className='comment-btn'>
                    <button>Post Comment</button>
                    </div> 
                
              
              
              </Form>
            </Card.Body>
          </div> 




       </Col>

       </Container>
   </Container>
   {/* input section here */}



    <Footer/>
        </Fragment>

  )
}

export default FirstPage