import React from "react";
import DetailHome from "../components/New-update-detail/NewUpdateDetail";
import DetailC from "../components/New-update-detail/detailCards";
import Trusted from "../components/About-Page/trusted";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

function DetailUpdate() {
  return (
    <div>
      <Helmet>
        <title>
                 Get the Latest News and Updates from the Sam Sahotra Foundation
        </title>
        <meta
          name="description"
          content=" Stay up-to-date with the latest news and updates from the Sam Sahotra Foundation. Get all the information you need right here!"
        />
        <link href="https://www.samsahotra.org/news-and-update/" rel="canonical"></link>
      </Helmet>
      <DetailHome />
      <DetailC />
      {/* <Trusted/> */}
      <Footer />
    </div>
  );
}

export default DetailUpdate;
