import React from 'react'
import ContactHome from '../components/Contact-Us/ContactUs'
import GetTouch from '../components/Contact-Us/getInTouch'
import Footer from '../components/Footer'
import Map from '../components/Contact-Us/map'
import { Helmet } from 'react-helmet'

function Contect() {
  return (
    <div>
      <Helmet>
        <title>Contact Us - Reach Out Sam 
                        Sahotra Foundation for Charity</title>
        <link rel="canonical" href="https://www.samsahotra.org/contact-us/" />
        <meta name='description' content="Contact us to get involved, donate, or volunteer for our charity causes.
 Together, we can make a difference in our community. Reach us at:+1 703-419-9000" />
      </Helmet>
   <ContactHome/>
   <GetTouch/>
   <Map/>
   <Footer/>

    </div>
  )
}

export default Contect