import React from "react";
import { Fragment } from "react";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import detailPage from "../../assets/img/New-update-detail/blog-image-1.jpg";
import Header from "../../components/Header";
import arrow from "../../assets/img/New-update-detail/Vector (24).svg";
import arrow1 from "../../assets/img/New-update-detail/Vector (25).svg";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
function FirstPage() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
      <Helmet>
        <title>Wellness Wisdom: 5 Nurturing Healthy Lifestyles | SSF</title>
        <meta
          name="description"
          content="5 Essential Tips for Nurturing Healthy Lifestyles and Transformation Overall Wellness and Healthy Lifestyle with Sam Sahotra Foundation"
        />
        <link
          rel="canonical"
          href="https://www.samsahotra.org/5-nurturing-healthy-lifestyles"
        />
      </Helmet>
      <Header />
      <Container fluid className="NewUpdateDetail1">
        {/* <Row className="header-content pt-5 mt-5  text-center align-center">
          <h2>News and Updates</h2>
          <p>Together... We can make a difference. </p>
          <h5>
            {" "}
            <Link
              to="/"
              onClick={scrollTop}
              style={{ textDecoration: "none", color: "white" }}
            >
              Home
            </Link>{" "}
            /{" "}
            <Link
              to="/detail"
              onClick={scrollTop}
              style={{ textDecoration: "none", color: "white" }}
            >
              News and Updates
            </Link>{" "}
          </h5>
        </Row> */}
      </Container>

      {/* ========================content and img==================================================== */}
      <Container fluid>
        <Container>
          <Row className="d-flex justify-content-end my-3 pt-2">
            {/* <Col md={8} className="">
              <img src={detailPage} className="card-img-top" alt=".." />
            </Col> */}
            {/* don't remove this col-4 */}
            <Col md={4}></Col>
            {/* don't remove this col-4 */}
          </Row>

          <Row className="d-flex justify-content-end  ">
            <Col md={12} className=" last-detail-page">
              <h1>Wellness Wisdom: 5 Nurturing Healthy Lifestyles</h1>

              <p align="justify" className="mt-3">
                The World Health Organization (WHO) defines health not as "the
                absence of any diseases in the human body, but the physical,
                mental, and social well-being of an individual." In contrast,
                wellness is the "ideal or optimal state of health of individuals
                and groups."
              </p>

              <p align="justify">
               Rohini Radhakrishnan, ENT, Head and Neck Surgeon,
                "Health is a goal, and wellness is a constant struggle or steps
                we take to achieve that goal."
              </p>
              
              <p align="justify">
                A healthy lifestyle is a choice that leads to an individual's
                well-being or wellness. Investment comes at the cost of making
                this choice to achieve a state of wellness. If you're someone
                who thought that 2024 was the year to stop being toxic to
                yourself and start taking your well-being seriously, we got you
                covered with our 5 health and wellness tips to add to your life.
              </p>

             

              <p>
                Let's explore how we can smoothly integrate these physical and
                spiritual habits into our lifestyle to make each day count, just
                like we make each life count at Sam Sahotra Foundation through
                our Healthy Body and Progressive Life initiative.
              </p>

              <h2 className="">Dimensions of Well-being </h2>
              <p>
                As we discussed earlier, having no physical or mental injuries
                or diseases makes a person healthy. However, wellness cannot be
                narrowed down to one dimension. Various studies have been
                conducted on wellness being multidimensional. One of the popular
                ones by{" "}
                <Link
                  to="https://www.medicinenet.com/what_is_health_and_wellness/article.htm"
                  target="_blank"
                >
                  <b>MedicineNet</b>
                </Link>{" "}
                highlights 6 dimensions to wellness. Here’s a quick look into
                each:
              </p>

              <h3>1. Physical </h3>
              <p align="justify">
                Individuals can start by making changes in this department to
                get closer to well-being. Let's say to prevent cardiovascular
                disease, one can start doing cardio for 15 minutes every day. Or
                one can stop smoking to have healthy lungs.
              </p>

              <h3>2. Intellectual </h3>
              <p align="justify">
                Intellectual wellness inculcates the areas directly dealt with
                by our IQ. Per say, problem-solving, logical reasoning, and
                feeding curiosity through creative support. Being intellectually
                fit requires fulfilling the demands of those areas, which we
                will discuss in detail in a minute.
              </p>

              <h3>3. Emotional </h3>
              <p align="justify">
                Emotional wellness is very significant. A person who is
                emotionally well can tackle stressful situations, fight with
                their emotions, and take measures to prevent situations for
                others where emotional damage can be detected.
              </p>

              <h3>4. Environmental </h3>
              <p align="justify">
                This dimension of wellness makes an individual aware of the role
                they play in the environment's well-being. They understand their
                position and use their power and wellness to prevent any natural
                or manufactured hazards to their environment.
              </p>

              <h3>5. Spiritual </h3>
              <p align="justify">
                Spiritual wellness is not about the religious faith of humans.
                Instead, it's the call of spirit for its purpose in the world.
                The purpose of existence comes within a soul and its wellness
                and connection with itself. In the words of Paul Gaugin, the
                author of the famous book till date, Noa Noa explains it
                beautifully, "I close my eyes in order to see." Similarly, if
                one is spiritually well, they don't get into a crisis of
                existence, but they connect themselves to find meaning in the
                life they are given.
              </p>

              <h3>6. Social </h3>
              <p align="justify">
                It's as simple as the concept of introvert and extrovert, which
                we've been categorizing since childhood. Someone who's socially
                more interactive and loves to network and build new relations is
                accepted socially as-well.
              </p>

              <h2 className="">5 Tips to Improve Wellness Dimensions </h2>
              <p>
                Now that we've discussed all 6 dimensions of wellness let's find
                out what we can collectively do to add healthy habits to each
                dimension so we can achieve a healthy lifestyle.
              </p>

              <h2 className="">Pay attention to the present </h2>
              <p>
                Living with the past can take your present away and a lot of
                energy, too. Living in the past induces unhealthy emotions such
                as guilt, shame, and regret. In order to build a lifestyle that
                focuses on the present, being mindful of where you are is
                important.{" "}
              </p>
              <p>
                The{" "}
                <Link
                  to="https://www.mayoclinichealthsystem.org/hometown-health/speaking-of-health/use-mindfulness-to-improve-well-being"
                  target="_blank"
                >
                  <b>Mayo Clinic</b>
                </Link>{" "}
                describes mindfulness as: "Mindfulness involves being acutely
                aware of your feelings and sensations at any given moment,
                without interpretation or judgment."{" "}
              </p>
              <p>
                When you stay mindful of your social and environmental
                dimensions, it promotes your well-being. It contributes to the
                following areas:{" "}
              </p>
              <p>
                <b>&#8226; </b>Less stress and better stress management skills{" "}
              </p>
              <p>
                <b>&#8226; </b> Better mood because of fewer worries from the
                past and the future{" "}
              </p>
              <p>
                <b>&#8226; </b> Stay focused on the work and stay less depressed
                and anxious{" "}
              </p>
              <h2>Be Physically Active </h2>
              <p>
                Did you know exercising thrice can elevate your mood and help
                with depression and stress? Studies show that moderate
                depression{" "}
                <Link
                  to="https://www.health.harvard.edu/mind-and-mood/exercise-is-an-all-natural-treatment-to-fight-depression"
                  target="_blank"
                >
                  <b>can be treated effectively by physical activity </b>
                </Link>{" "}
                but without side effects. Start small if you want to reap all
                the benefits of exercise. A study by the Harvard T.H. Chan
                School of Public Health found that{" "}
                <Link
                  to="https://www.health.harvard.edu/mind-and-mood/more-evidence-that-exercise-can-boost-mood"
                  target="_blank"
                >
                  <b>
                    walking or running 15 minutes a day for one hour can reduce
                    the risk of depression by 26.{" "}
                  </b>
                </Link>
              </p>
              <h2>Adopt a New Skill or Habit</h2>

              <p>
                Adopting a new skill can help one contribute to the well-being
                of oneself. Plato, in his book Apology of Socrates epically
                said, "What I know is that I know nothing." This refers to the
                philosophical aspect of not closing the doors of learning ever
                in life, and it also helps nurture a healthy lifestyle. Maybe if
                you're a chef or love cooking, the next step can be learning
                baking. If you know how to drive a car, you can learn how to
                ride a bike.
              </p>

              <h2>Give to Others</h2>

              <p>
                Being generous feels excellent, but the question is, does it
                contribute to one's well-being? The answer is a big yes! If you
                want to give away, start by contributing to the environment.
                Build a community, and maybe donate to organizations, including
                the{" "}
                <Link
                  to="https://www.samsahotra.org/give/samsahotra"
                  target="_blank"
                >
                  <b>Sam Sahotra Foundation, </b>
                </Link>
                as every penny you give us counts.
              </p>

              <h2>Connect with Community</h2>

              <p>
                Connecting with people around you helps you socialize and build
                a community of people who can change the world. One human who
                interacts with a bunch of people creates an impact on their
                lives. Now, in many cases, that impact can be ever-lasting and
                boosts the confidence and self-esteem of a person, helping
                himself and others build a community.
              </p>
              <p>
                Seamlessly incorporating these into your daily life helps you
                build a healthy lifestyle that contributes to wellness and
                nourishes your health.
              </p>
              <h2>Key Takeaways </h2>
              <p>
                In conclusion, health and well-being are more than just a "nice
                to have"- essential for our happiness and fulfillment as
                parents, employees, partners, and people.
              </p>
              <p>
                In today's fast-paced, often chaotic world, health and
                well-being can easily be compromised. There are many ways to
                improve our physical and mental health and promote well-being in
                our everyday lives. It all starts with understanding the factors
                that create well-being and how we can increase it.{" "}
              </p>
              <p>
                We can improve our health and well-being by focusing on
                mindfulness, giving, moving, and learning. At Sam Sahotra, we
                are constructing a secure society for individuals dedicated to
                cultivating and making this world a safe place to live. If you
                are experiencing any difficulty in life, please do not hesitate
                to contact us or let your friends know about us. We are the
                ambassadors of peace and believe no one should fight their
                battles alone.{" "}
              </p>
            </Col>
          </Row>
        </Container>

        {/* Social-Share-media */}
        <Container>
          <Row className="d-flex how-small justify-content-between mt-4 pt-1"></Row>
        </Container>
      </Container>

      {/* Social-Share-media end */}

      {/* input section here */}

      <Container fluid className="  input-last-section ">
        <Container className="mt-5 pt-5 d-flex justify-content-center">
          <Col md={10} className=" pt-3 d-flex justify-content-center">
            <div className="col-11 ">
              <div className="  last-detail-page">
                <h4>Leave a Reply</h4>
                <p className="pt-2 leave-p">
                  Your email address will not be published. Required fields are
                  marked *
                </p>
              </div>
              <Card.Body className="Leave-Reply">
                <Form className="input-main">
                  <Row className="mb-2">
                    <Col md={6} className="mb-1">
                      <Form.Control
                        type="name"
                        className="Form-Control1"
                        placeholder="First Name"
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Control
                        type="name"
                        className="Form-Control1"
                        placeholder="Last Name"
                      />
                    </Col>

                    <Col md={12} className="mt-4">
                      <Form.Control
                        type="Email"
                        className="Form-Control1"
                        placeholder="Enter Websites"
                      />
                    </Col>
                  </Row>

                  <div className="mt-4">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      className="mb-4 Form-Controlll"
                      placeholder="Any Comment"
                    />
                  </div>

                  <div className="comment-btn">
                    <button>Post Comment</button>
                  </div>
                </Form>
              </Card.Body>
            </div>
          </Col>
        </Container>
      </Container>
      {/* input section here */}

      <Footer />
    </Fragment>
  );
}

export default FirstPage;
