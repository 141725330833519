import React, { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import dd from "../assets/img/newupdated/card-img.jpg";
import second from "../assets/img/newupdated/SSF-banner.png";
import third from "../assets/img/newupdated/mission-christmas.png";
import { Link } from "react-router-dom";
function newUpadate() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
      <div className=" update-main pt-4  ">
        <div className="container ">
          <div className="row conten-ccenter ">
            <div className="col-5 update-content  pt-5 mt-2">
              <div className="width">
                <h2 className="Support">News and Updates</h2>
              </div>
            </div>
            <div className="col-7  update-contentt pt-5 mt-2 col-md-center  ">
              <p>
                Stay informed about the latest developments in our charity
                campaign and how you can get involved.
              </p>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/news-and-update"
                onClick={scrollTop}
              >
                <div className="view-all">
                  <button className="mt-4">View All</button>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <Container className="pt-5 mt-4  ">
          <Row className=" detail-content-box ">
            <Col md={4} className="">
              <div>
                <div>
                  <img
                    src={dd}
                    alt="Sam Sahotra Foundation"
                    className="card-img-top"
                    loading="lazy"
                  />
                </div>

                <div className="card-content">
                  <h3 className="mt-4 make">
                  Wellness Wisdom: 5 Nurturing Healthy Lifestyles
                  </h3>
                  <p className="card-p" align="justify">
                  The World Health Organization (WHO) defines health not as "the absence of any diseases in the human body, but the physical, mental, and social well-being of an individual." In contrast, wellness is the "ideal or optimal state of health of individuals and groups."
                  </p>

                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to="/nurturing-healthy-lifestyles"
                    onClick={scrollTop}
                  >
                    <button className="mt-3">Read More</button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4} className="">
              <div>
                <div>
                  <img
                    src={third}
                    alt="Sam Sahotra Foundation"
                    className="card-img-top"
                    loading="lazy"
                  />
                </div>

                <div className="card-content">
                  <h4 className="mt-4">
                    Get Involved and Make a Difference in the World
                  </h4>
                  <p className="card-p" align="justify">
                    If you're looking to make a difference in the world, there
                    are many ways to get involved and make a positive impact.
                    From volunteering your time to donating to a cause, there
                    are countless opportunities to make a difference in your
                    community and beyond.
                  </p>
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to="/get-involved-and-make-a-difference"
                    onClick={scrollTop}
                  >
                    <button className="mt-3">Read More</button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4} className="">
              <div>
                <div>
                  <img
                    src={second}
                    alt="Sam Sahotra Foundation"
                    className="card-img-top"
                    loading="lazy"
                  />
                </div>

                <div className="card-content">
                  <h4 className="mt-4">
                    Getting Involved in Your Local Charity Scene
                  </h4>
                  <p className="card-p">
                    Are you looking for ways to give back to your community and
                    make a difference? Getting involved in local charities is a
                    great way to do just that. From volunteering at a local
                    animal shelter to participating in a charity walk, there are
                    many ways to get involved and make a positive impact.
                  </p>
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to="/local-charity-guide"
                    onClick={scrollTop}
                  >
                    <button className="mt-3">Read More</button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}

export default newUpadate;
