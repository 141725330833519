import React from 'react'
import { Fragment } from 'react'
import { Container, Row } from 'react-bootstrap'
 import Header from '../Header'
 import { Link } from "react-router-dom";
function joinHome() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
    <Header/>
    <Container fluid className='join-Home'>
                 
            </Container>
    
        </Fragment>
  )
}

export default joinHome