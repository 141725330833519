import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import { Fragment } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
// import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos1 } from "./photo1";
import { photos2 } from "./photo2";
import Val from "../../components/Our-Mission/volunter2";
import Footer from "../../components/Footer";

function Quenching() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const [currentImage2, setCurrentImage2] = useState(0);
  const [viewerIsOpen2, setViewerIsOpen2] = useState(false);

  const openLightbox2 = useCallback((event, { photo, index }) => {
    setCurrentImage2(index);
    setViewerIsOpen2(true);
  }, []);

  const closeLightbox2 = () => {
    setCurrentImage2(0);
    setViewerIsOpen2(false);
  };
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };
  return (
    <>
      <div>
        <Header />
        <Container fluid className="header-mission-Tobatek "></Container>
        <div className=" mt-3 mb-5">
          <div className="container Success mb-5">
            <h2 className="pb-3">
              Launching a Clean Drinking Water Project in Toba Tek Singh Village{" "}
            </h2>
            <div className="row">
              <div className="col-8">
                <p
                  style={{
                    fontSize: "23px",
                    textAlign: "justify",
                    lineHeight: "40px",
                  }}
                >
                  The Sam Sahotra Foundation has a mission to transform lives
                  via charity giving and sustainable solutions. The Sam Sahotra
                  Foundation recently stepped-into beginning a journey to serve
                  rural community through granting them access to the safe
                  drinking water in a village located in Central Punjab's Toba
                  Tek Singh District, Pakistan. Join hands with Sam Sahotra
                  Foundation to provide clean water and transform lives.
                  Together, we can work to contribute to and bring about a
                  long-term change, One Pump at a Time, to give everyone access
                  to its fundamental human right to clean water.
                </p>
              </div>
              <div className="col-4">
                <Val />
              </div>
            </div>
            <h2
              className="pb-3"
              style={{
                fontSize: "33px",
              }}
            >
              The Sam Sahotra Foundation's Community Water Pump Project in the
              Village of Toba Tek Singh District{" "}
            </h2>

            <p
              className="mt-8"
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
              }}
            >
              Accessibility to clean water is a much-needed necessity in rural
              areas. This necessity was out of reach and a faraway fantasy for
              the local community. The Sam Sahotra Foundation has a mission to
              transform lives via charity giving and sustainable solutions. The
              Sam Sahotra Foundation recently stepped-into beginning a journey
              to serve rural community through granting them access to the safe
              drinking water in a village located in Central Punjab's Toba Tek
              Singh District, Pakistan. A community representative from Chak
              300/GB Village in Toba Tek Singh District, facing this problem,
              contacted the Sam Sahotra Foundation for help.
            </p>

            <p
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
                paddingTop: "20px",
              }}
            >
              {" "}
              On March 20, 2024, the Sam Sahotra Foundation team visited the
              Chak 300/GB Village in Toba Tek Singh District. Around 25
              households were living there in miserable conditions. The small
              community lacked the availability of basic human life necessities
              such as drinking water, power, and a basic health care unit. The
              community faced a lot of difficulty acquiring water through a
              relatively distant source. During this tour, the Sam Sahotra
              Foundation team worked to uplift and inspire community members,
              developing a sense of unity and solidarity among them. The Sam
              Sahotra Foundation team showed its commitment and dedication to
              providing the best possible moral assistance and support to the
              community.
            </p>

            <p
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
                paddingTop: "20px",
              }}
            >
              On April 3, 2024, the Sam Sahotra Foundation team visited the Chak
              300/GB’s village community once again. This time, the Sam Sahotra
              Foundation team set up a drinking water facility by installing a
              community water pump at the village community's doorstep. This
              hand water pump will satisfy thirst and provide hope, growth, and
              a brighter future for the entire town. The community responded by
              thanking and appreciating Sir Sam Sahotra, the head and founder of
              the Sam Sahotra Foundation, for his generosity, dedication, and
              commitment to serving humanity.  
            </p>
            <p
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
                paddingTop: "20px",
              }}
            >
              Sam Sahotra Foundation is a non-profit organization striving to
              create a bright future for every community by transforming lives.
              Join hands with Sam Sahotra Foundation to transform the impact of
              pure water. Together, we can contribute to bringing about a
              long-term change, one pump at a time, to give everyone access to
              their fundamental human right to clean water.
            </p>
          </div>
        </div>

        {/* // FIRST GALLERY */}
        <Container>
          <div>
            <div className="gallery">
              {photos2.map((photo) => (
                <div key={photo.id} className="photo">
                  <img
                    src={photo.src}
                    alt={photo.title}
                    loading="lazy"
                    onClick={() => handlePhotoClick(photo)}
                  />
                </div>
              ))}
            </div>
            {selectedPhoto && (
              <div className="selected-photo">
                <h2>{selectedPhoto.title}</h2>
                <img src={selectedPhoto.src} alt={selectedPhoto.title} loading="lazy" />
              </div>
            )}
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Quenching;
