import React from "react";
import SuccessStory from "./SuccessStory";
const SuccesMain = () => {
  return (
    <div>
      <div className="mt-5 mb-5">
        <div className="container Success mb-5">
          <h2>Success Story</h2>
          <p>
            We would like to show you, clearly how much difference your help
            makes.
          </p>
        </div>

        <SuccessStory />
      </div>
    </div>
  );
};

export default SuccesMain;
