import React from 'react';

import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import cashAppLogo from '../../assets/img/png-image.png';
import venmoLogo from '../../assets/img/zelle-app-logo.png';
import paypalLogo from '../../assets/img/Paypal_2014_logo.png';
import pushpayLogo from '../../assets/img/Pushpay_logo_Red_RGB_Wordmark_Stacked.png';
import dd from '../../assets/img/newupdated/first.png';
import second from '../../assets/img/newupdated/second.png';
import third from '../../assets/img/newupdated/Rectangle 140 (2).png';
import { Link } from 'react-router-dom';
const DonationPage = () => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  const handleDonate = (method) => {
    // Donation handling logic for the selected method
    console.log(`Donating via ${method}`);
    // Add logic here to redirect user or handle donation process
  };
  return (
    <Container className="pt-5 mt-4 pb-5">
      <Row className="justify-content-center">
        <Col md={3} className="mt-2">
          <Card
            className="text-center p-4"
            style={{
              textAlign: 'center',
              color: '#1E417B',
              border: 'none',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#ffffff',
              borderRadius: '10px',
            }}
          >
            <Card.Img
              variant="top"
              src={cashAppLogo}
              style={{ width: '185px', height: 'auto', margin: 'auto' }}
            />
            <Card.Body>
              <Card.Title
                as="h3"
                className="mt-4 donation_Card_text"
                style={{ color: '#1E417B' }}
              >
                Cash App
              </Card.Title>
              {/* <Card.Text style={{ color: '#1E417B', fontSize: '16px' }}>
                  Donate using Cash App for a seamless experience.
                </Card.Text> */}
              <a target="blank" href="https://cash.app/$samsahotrafoundation">
                <Button variant="primary" className="mt-3">
                  Donate
                </Button>
              </a>
            </Card.Body>
          </Card>
        </Col>

        {/* Add more Col components for other payment methods */}
        <Col md={3} className="mt-2">
          <Card
            className="text-center p-4"
            style={{
              textAlign: 'center',
              color: '#1E417B',
              border: 'none',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#ffffff',
              borderRadius: '10px',
            }}
          >
            <Card.Img
              variant="top"
              src={venmoLogo}
              style={{ width: '200px', height: 'auto', margin: 'auto' }}
            />
            <Card.Body>
              <Card.Title
                as="h3"
                className="mt-4 donation_Card_text"
                style={{ color: '#1E417B', textAlign: 'center' }}
              >
                Zelle
              </Card.Title>
              {/* <Card.Text style={{ color: '#1E417B', fontSize: '16px' }}>
                  Donate using Venmo  for a seamless experience.
                </Card.Text> */}

              {/* <a target="blank" href="https://venmo.com/u/samsahotrafoundation"> */}
              <Button variant="primary" className="mt-3">
                703-665-9000
              </Button>
              {/* </a> */}
            </Card.Body>
          </Card>
        </Col>

        <Col md={3} className="mt-2">
          <Card
            className="text-center p-4"
            style={{
              textAlign: 'center',
              color: '#1E417B',
              border: 'none',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#ffffff',
              borderRadius: '10px',
            }}
          >
            <Card.Img
              variant="top"
              src={paypalLogo}
              style={{ width: '200px', height: 'auto', margin: 'auto' }}
            />
            <Card.Body>
              <Card.Title
                as="h3"
                className="mt-4 donation_Card_text"
                style={{ color: '#1E417B' }}
              >
                PayPal
              </Card.Title>
              {/* <Card.Text style={{ color: '#1E417B', fontSize: '16px' }}>
                  Donate using   PayPal  for a seamless experience.
                </Card.Text> */}
              <a
                target="blank"
                href="https://www.paypal.com/US/fundraiser/charity/4913692"
              >
                <Button variant="primary" className="mt-3">
                  Donate
                </Button>
              </a>
            </Card.Body>
          </Card>
        </Col>

        <Col md={3} className="mt-2">
          <Card
            className="text-center p-4"
            style={{
              textAlign: 'center',
              color: '#1E417B',
              border: 'none',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#ffffff',
              borderRadius: '10px',
            }}
          >
            <Card.Img
              variant="top"
              src={pushpayLogo}
              style={{ width: '200px', height: 'auto', margin: 'auto' }}
            />
            <Card.Body>
              <Card.Title
                as="h3"
                className="mt-4 donation_Card_text"
                style={{ color: '#1E417B' }}
              >
                Debit/Credit
              </Card.Title>
              {/* <Card.Text style={{ color: '#1E417B', fontSize: '16px' }}>
                  Donate using Pushpay   for a seamless experience.
                </Card.Text> */}
              <a href="https://pushpay.com/g/samsahotra" target="blank">
                <Button variant="primary" className="mt-3">
                  Donate
                </Button>
              </a>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DonationPage;
