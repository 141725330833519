import React from 'react'
import JoinS from '../components/Join-ssf/joinHome'
import Become from '../components/Join-ssf/becomeVolunter'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'
function Join() {
  return (
    <div>
      <Helmet>
        <title>Join Us and Make a Difference: The Sam Sahotra Foundation's Join Us Page </title>
        <link rel="canonical" href="https://www.samsahotra.org/join" />
        <meta name='description' content="Join the Sam Sahotra Foundation and be a part of making a difference in the world. Learn more about our Join Us page and how you can get involved today!" />
      </Helmet>
    <JoinS/>
    <Become/>
    <Footer/>

    </div>
  )
}

export default Join