import React, {useState} from 'react'
import { Fragment, } from 'react'
import { Button, Card, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import vol from '../../assets/img/Join-ssf/volunter.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HashLoader from 'react-spinners/ClipLoader';
function BecomeVolunter() {

  const [loading, setLoading] = useState(true)
  const [volunteer, setVolunteer] = useState({

    first:"",
    last:"",
    email:"",
    phone:"",
    address: "",
    address2: "",
    city:"",
    region: "",
    zipcode: "",
    country: "",
    comment: "",
  })

  
const {first, last, email, phone, address, city, region, zipcode, country,comment} = volunteer



const HandleChange = (e) => setVolunteer({...volunteer, [e.target.name] : e.target.value})
const HandleSubmit = () => {
  setLoading(false)
  fetch(`${process.env.REACT_APP_SERVER_URL}/ssfVolunteer`, {
    method:"POST",
    headers: {
      "content-Type":"application/json",
    },
    body:JSON.stringify(
      volunteer
    )
  })
  .then((res) => res.json())
  .then((data) => {
    console.log(data)
    if(!data.success)
    {
      setLoading(true)
      toast.error(data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
    else 
    {
  setLoading(true)
      
      toast.success(data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
      
  })












  // if(!name || !email || !phone || !subject || !comment )
  // {
  //   toast.error('please fill all fileds', {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "light",
  //     });
  // }
  // else 
  // {
  //   alert('happy ending')
  // }

}
console.log(volunteer)
  return (
    <Fragment>
    <div fluid className=' become-vol-main-header'>
<Row className='become-row'>
<Col md={6} className=''>


        <Col md="11" bg={'transparent'} className="mb-4  mt-5 pt-4 ">
          <div className="">
            <div className="py-1 become-content get-in-touch-main">
              <div className='Become-a'>

              <h2>Become a Volunteer</h2>
              </div>
              <p>Ready to Help other in need? Become volunteer, because increasing grass-root community involvement is important to stay close the people in need of real help.</p>
            </div>
            <Card.Body className='get-in-touch-main'>
              <Form className='input-main'>
                <Row className="mb-2">
                  <Col md={6} className='mb-1'>
                    <Form.Label className='lebel'>First name</Form.Label>
                    <Form.Control  name='first'  value={first} onChange={HandleChange} type="name" className='Form-Control' placeholder='First Name'/>
                  </Col>
                  <Col md={6}>
                    <Form.Label className='lebel'>Last name</Form.Label>
                    <Form.Control name='last'  value={last} onChange={HandleChange} type="name" className='Form-Control' placeholder='Last Name'/>
                  </Col>

                  <Col md={6}>
                    <Form.Label className='lebel'>Email</Form.Label>
                    <Form.Control name='email'  value={email} onChange={HandleChange} type="Email" className='Form-Control' placeholder='Email'/>
                  </Col>
                  <Col md={6}>
                    <Form.Label className='lebel'>Phone</Form.Label>
                    <Form.Control name='phone'  value={phone}  onChange={HandleChange} type="number" className='Form-Control' placeholder='Phone'/>
                  </Col>

                </Row>

                <Form.Label className='lebel'>Address</Form.Label>
                <Form.Control name='address'  value={address} onChange={HandleChange}  type="Address" className="mb-3 Form-Control" placeholder='Street Address' />
               
              

                <Row className="mb-1">
                  <Col md={6} className='mb-1 mt-1'>
              
                    <Form.Control name='city'  value={city} onChange={HandleChange} type="City" className=' mb-3 Form-Control' placeholder='City'/>
                  </Col>
                  <Col md={6} className='mb-1 mt-1' >
            
                    <Form.Control name='region'  value={region} onChange={HandleChange}  type="text" className='Form-Control' placeholder='Region'/>
                  </Col>

                  <Col md={6}>
              
                    <Form.Control name='zipcode'  value={zipcode}  onChange={HandleChange} type="Code" className='Form-Control' placeholder='Postal /Zip Code'/>
                  </Col>
                  <Col md={6}>
            
                    <Form.Control  name='country'  value={country} onChange={HandleChange} type="Country" className='Form-Control' placeholder='Country'/>
                  </Col>

                </Row>


                
                <Form.Label className='lebel'>Any Comment</Form.Label>
                <Form.Control as="textarea" name='comment'  value={comment} onChange={HandleChange}   rows={4} className="mb-4 Form-Controll" placeholder='Any Comment' />
              </Form>
              <div className=' d-flex justify-content-center partner-btn'>
                 <Button onClick={HandleSubmit} className=''> {loading  ?  "submit" : <><HashLoader
                                    color="white"
                                    size={30}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  /> </>} </Button>
              </div>
            </Card.Body>
          </div>
        </Col>
</Col>
<Col md={4} className='mb-4 vol-img ' >
        <div className='pt-5 '>
            <img src={vol} className='card-img-top' alt=".." />
        </div>
    </Col>

</Row>




    </div>


    </Fragment>
  )
}

export default BecomeVolunter