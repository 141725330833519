import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Causes from "../../assets/img/about-page/agency-icon/first.svg";
import Valunteer from "../../assets/img/about-page/agency-icon/Valunteer.svg";
import Childrens from "../../assets/img/about-page/agency-icon/Childrens.svg";
import Countries from "../../assets/img/about-page/agency-icon/Countries.svg";

function ourAgency() {
  return (
    <Fragment>
      <Container fluid className="ourAgency-main pt-5">
        <Container className=" pt-5">
          <div className="ouragency-content mt-5">
            <h2>
              SSF foundation working hard since years to make the best around us
              for everyone.
            </h2>
          </div>
          <Row className=" mt-5 pt-3  ">
            <Col md={3} className="text-center ouragency-content">
              <div className="   d-flex justify-content-center">
                <img
                  src={Causes}
                  className="img-fliud"
                  alt="sam-sahotra-foundation"
                  loading="lazy"
                />
              </div>
              <h4>Causes</h4>
            </Col>

            <Col md={3} className="text-center ouragency-content">
              <div className=" pt-4 d-flex justify-content-center">
                <img
                  src={Valunteer}
                  className="img-fliud"
                  alt="sam-sahotra-foundation"
                  loading="lazy"
                />
              </div>
              <h4 className="">Volunteer</h4>
            </Col>

            <Col md={3} className="text-center ouragency-content">
              <div className="pt-3 d-flex justify-content-center">
                <img src={Childrens} className="img-fliud" alt="sam-sahotra-foundation" loading="lazy" />
              </div>
              <h4>Childrens</h4>
            </Col>

            <Col md={3} className="text-center ouragency-content">
              <div className=" d-flex justify-content-center">
                <img src={Countries} className="img-fliud" alt="sam-sahotra-foundation" loading="lazy" />
              </div>
              <h4>Countries</h4>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>
  );
}

export default ourAgency;
