import React, { Fragment } from "react";
import whopic from "../assets/img/New_Header_Pic/SSF-picture 7.png";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
function whoWe() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
      <div className="container   whowe-main  mb-5">
        <div className="row d-flex justify-content-center mb-4">
          <div className="col-md-8 who-we">
            <div className=" text-center ">
              <p>who we are</p>
              <h2>
                We support causes such as education, healthcare, and poverty
                alleviation.
              </h2>
            </div>
          </div>
        </div>

        <Row>
          <Col md={6} className=" d-flex justify-content-center">
            <Col md={9} className=" d-flex align-items-end ">
              <img src={whopic} alt="Sam Sahotra Foundation" className="card-img-top" loading="lazy"></img>
            </Col>
          </Col>
          <div className="col-md-6 content-box   paragraph">
            <p className="mt-5 its-founder   pt-5">
              Its founder, Sam Sahotra, is dedicated to making a positive impact
              on individuals and communities worldwide through philanthropy.
            </p>
            <div className="col-12 d-flex mt-5 ">
              <div className="col-2  ">
                <div className="education-img m-lg-2"></div>
              </div>
              <div className="col-10 whowe-h4  ">
                <h3 className="Emporwing"> Empowering Education</h3>
                <p className="paragraph">
                  Enhancing educational opportunities for underprivileged
                  children and youth, ensuring they have access to quality
                  schooling, scholarships, and vocational training.{" "}
                </p>
              </div>
            </div>

            <div className="col-12 mt-3 d-flex ">
              <div className="col-2 ">
                <div className="health-img m-lg-2"></div>
              </div>
              <div className="col-10 whowe-h4 ">
                <h4>Enhancing Healthcare</h4>
                <p className="paragraph">
                  SSF focuses on improving healthcare infrastructure, providing
                  medical assistance to marginalized communities, and supporting
                  research and innovation in medical science.
                </p>
              </div>
            </div>

            <div className="col-12  mt-3 d-flex ">
              <div className="col-2 ">
                <div className="food-img m-lg-2"></div>
              </div>
              <div className="col-10 whowe-h4 ">
                <h4>Feeding the Underprivileged</h4>
                <p className="paragraph">
                  Feeding the underprivileged ones is a compassionate global
                  endeavor aimed at providing sustenance and support to those
                  facing food insecurity.
                </p>
              </div>
            </div>
            <Link
              to="/our-mission"
              onClick={scrollTop}
              style={{ textDecoration: "none" }}
            >
              <div className="mt-4 read-more partner-btn">
                <button>READ MORE</button>
              </div>
            </Link>
          </div>
        </Row>
      </div>
    </Fragment>
  );
}

export default whoWe;
