import React from 'react'
import { Fragment } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import donor from '../../assets/img/Our_mission/donor.svg'
import { Link } from 'react-router-dom'

function volunter() {
    const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
<div className='volunter-main mt-4 '>
    <Container className=' counter-container '>

<Row className='volunter-row '>
    <Col md={3} className=' box-bg-color box-content' >
        <Link to='/give/samsahotra'  style={{textDecoration:'none'}}>
        <Row  className=' box-content text-center' >

        <div className='  donor'></div>
        <h4>Give Now !</h4>
        <p>Support the Cause </p>
        <Link to='/give/samsahotra'  style={{textDecoration:'none'}}>
        <Button>Give Now </Button>
        </Link>
        </Row>
        </Link>
    </Col>
   

    <Col md={3} className=' box-bg-color box-content' >
        <Link  to='/join' onClick={scrollTop} style={{textDecoration:'none'}}>
        <Row  className=' box-content text-center' >

        <div className='volun-pic'></div>
        <h4>Become a Volunteer</h4>
        <p>Help people in need.</p>
        <Button>Join SSF</Button>
        </Row>
        </Link>
    </Col>


   


    <Col md={3} className=' box-bg-color box-content' >
        <Row  className=' box-content text-center' >

        <div className=' call-pic'></div>
        <h4>Give us a Free Call</h4>
        <p>Be a reason for someone' happiness</p>
       <h4>+1 (703) 665-9000</h4>
        </Row>
    </Col>

   
</Row>
    </Container>





</div>



    </Fragment>
  )
}

export default volunter