import React, { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import dd from "../assets/img/newupdated/first.png";
import second from "../assets/img/newupdated/second.png";
import third from "../assets/img/newupdated/Rectangle 140 (2).png";
import pic1 from "../assets/img//jer2/sff-school-stationary-charity.jpg";
import pic2 from "../assets/img/jer2/sff-school-book-stationary-charity.jpg";
import pic3 from "../assets/img/jer2/sam-sahotra-foundation.jpg";
import pic4 from "../assets/img/jer/WhatsApp Image 2023-08-20 at 5.30.40 AM.jpeg";
import pic5 from "../assets/img/jer/WhatsApp Image 2023-08-20 at 5.31.03 AM.jpeg";
import pic6 from "../assets/img/jer/WhatsApp Image 2023-08-20 at 5.30.50 AM.jpeg";

import { Link } from "react-router-dom";
const Quenching = () => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const data = [pic1, pic2, pic3];
  return (
    <div>
      <div className="container pb-md-5">
        <div className="container Success mb-5">
          <h2>Empowering Orphans Through Educational Upliftment</h2>

          <p
            style={{
              
              fontSize: "22px",
              letterSpacing: "2px",
            }}
          >
            The SSF is committed to providing orphaned children in Khand village
            with comprehensive support and opportunities to break the cycle of
            poverty through education and personal development. Their long-term
            ambition is to empower these children to lead fulfilling lives and
            contribute positively to their communities and society at large.
          </p>
          <div className="d-flex">
            <Link to="/give/samsahotra">
              <button
                className="paypal_donate mt-2"
                style={{ background: "#4D7C8A" }}
              >
                Give Now
              </button>
            </Link>

            <Link
              to="/OrphanEdu"
              onClick={scrollTop}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <button className="paypal_donate mt-2">Read More </button>
            </Link>
          </div>
        </div>
        <Container className="pt-5 mt-4 mb-4 ">
          <Row className=" detail-content-box ">
            {data?.map((photo, i) => {
              return (
                <>
                  <Col md={4} className="">
                    <div>
                      <div>
                        <img
                          src={photo}
                          className="card-img-top mt-3"
                          height={260}
                          alt="Sam Sahotra Foundation"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Quenching;
