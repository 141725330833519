import pic1 from "../../assets/img/mission-christmas/mission-christmas1.png";
import pic2 from "../../assets/img/mission-christmas/mission-christmas2.png";
import pic3 from "../../assets/img/mission-christmas/mission-christmas3.png";
import pic4 from "../../assets/img/mission-christmas/mission-christmas4.png";
import pic5 from "../../assets/img/mission-christmas/mission-christmas5.png";
import pic6 from "../../assets/img/mission-christmas/mission-christmas6.png";
import pic7 from "../../assets/img/mission-christmas/mission-christmas7.png";
import pic8 from "../../assets/img/mission-christmas/mission-christmas8.png";
import pic9 from "../../assets/img/mission-christmas/mission-christmas9.png";
import pic10 from "../../assets/img/mission-christmas/mission-christmas10.png";
import pic11 from "../../assets/img/mission-christmas/mission-christmas11.png";
import pic12 from "../../assets/img/mission-christmas/mission-christmas12.png";
import pic13 from "../../assets/img/mission-christmas/mission-christmas13.png";
import pic14 from "../../assets/img/mission-christmas/mission-christmas14.png";
import pic15 from "../../assets/img/mission-christmas/mission-christmas15.png";
import pic16 from "../../assets/img/mission-christmas/mission-christmas16.png";
import pic17 from "../../assets/img/mission-christmas/mission-christmas17.png";
import pic18 from "../../assets/img/mission-christmas/mission-christmas18.png";
// import pic19 from "../../assets/img/mission-christmas/mission-christmas19.png";

export const photos2 = [
  {
    src: pic1,
    width: 3,
    height: 2,
  },
  {
    src: pic2,
    width: 3,
    height: 2,
  },
  {
    src: pic3,
    width: 3,
    height: 2,
  },
  {
    src: pic4,
    width: 3,
    height: 2,
  },
  {
    src: pic5,
    width: 3,
    height: 2,
  },

  {
    src: pic6,
    width: 3,
    height: 2,
  },
  {
    src: pic7,
    width: 3,
    height: 2,
  },
  {
    src: pic8,
    width: 3,
    height: 2,
  },
  {
    src: pic9,
    width: 3,
    height: 2,
  },
  {
    src: pic10,
    width: 3,
    height: 2,
  },
  {
    src: pic11,
    width: 3,
    height: 2,
  },
  {
    src: pic12,
    width: 3,
    height: 2,
  },
  {
    src: pic13,
    width: 3,
    height: 2,
  },
  {
    src: pic14,
    width: 3,
    height: 2,
  },
  {
    src: pic15,
    width: 3,
    height: 2,
  },
  {
    src: pic16,
    width: 3,
    height: 2,
  },
  {
    src: pic17,
    width: 3,
    height: 2,
  },
  {
    src: pic18,
    width: 3,
    height: 2,
  },
  // {
  //   src: pic19,
  //   width: 3,
  //   height: 2,
  // },
  
];
