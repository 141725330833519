import React from "react";
import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../Header";
// import { Link } from "react-router-dom";
import videoPic from "../../assets/img/about-page/Youtube Video.svg";
import { Link } from "react-router-dom";
function Abouthome() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Fragment>
      <div>
        <Header />
        <link rel="canonical" href="https://www.samsahotra.org/about-us/" />
        <Container fluid className=" header-mission-pic ">
          <Row className="header-content pt-5 mt-2  text-center align-center">
           
          </Row>
          <div>
            <Row className="d-flex justify-content-center">
              <Col md={5} className=" pt-2">
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Fragment>
  );
}

export default Abouthome;
