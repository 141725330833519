import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import { Fragment } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
// import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos1 } from "./photo1";
import { photos2 } from "./photo2";
import Val from "../../components/Our-Mission/volunter2";
import pic4 from "../../assets/img/jer2/4.jpeg";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
function Jaranwala() {
  const photos = [
    { id: 1, src: pic4, title: "Photo 1" },
    { id: 2, src: pic4, title: "Photo 1" },
    { id: 3, src: pic4, title: "Photo 1" },
    { id: 4, src: pic4, title: "Photo 1" },
    { id: 5, src: pic4, title: "Photo 1" },
    { id: 6, src: pic4, title: "Photo 1" },
    { id: 7, src: pic4, title: "Photo 1" },
    { id: 8, src: pic4, title: "Photo 1" },
    // Add more photo objects as needed
  ];

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const [currentImage2, setCurrentImage2] = useState(0);
  const [viewerIsOpen2, setViewerIsOpen2] = useState(false);

  const openLightbox2 = useCallback((event, { photo, index }) => {
    setCurrentImage2(index);
    setViewerIsOpen2(true);
  }, []);

  const closeLightbox2 = () => {
    setCurrentImage2(0);
    setViewerIsOpen2(false);
  };
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };
  return (
    <Fragment>
      <Helmet>
        <title>
          Jaranwala News & Blasphemy Updates and help effected people
        </title>
        <meta
          name="description"
          content="Stay informed with Jaranwala news and blasphemy updates.
          Join Sam Sahotra Foundation in aiding affected communities"
        />
        <link
          rel="canonical"
          href="https://www.samsahotra.org/news-and-update-jaranwala/"
        />
      </Helmet>
      <div>
        <Header />
        <Container fluid className="header-mission-picc_jer "></Container>
        <div className=" mt-3 mb-5">
          <div className="container Success mb-5">
            <h2 className="pb-3">Community Rebuilding</h2>
            <div className="row">
              <div className="col-8">
                <p
                  className=""
                  style={{
                    fontSize: "23px",
                    textAlign: "justify",
                    lineHeight: "40px",
                  }}
                >
                  Muslims in eastern Pakistan went on a rampage Wednesday over
                  allegations that a Christian man had desecrated the Quran,
                  demolishing the man’s house, burning churches and damaging
                  several other homes, police and local Christians said. There
                  were no immediate reports of casualties. The scale of the
                  violence prompted the government to deploy additional police
                  forces and send in the army to help restore order.
                </p>
              </div>
              <div className="col-4">
                <Val />
              </div>
            </div>

            <p
              className="mt-4"
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
              }}
            >
              The attacks in Jaranwala, in the district of Faisalabad in Punjab
              province, erupted after some Muslims living in the area claimed
              they had seen a local Christian, Raja Amir, and his friend tearing
              out pages from a Quran, throwing them on the ground and writing
              insulting remarks on other pages. Police chief Rizwan Khan said
              this had angered the local Muslims. A mob gathered and began
              attacking multiple churches and several Christian homes, burning
              furniture and other household items. Some members of the Christian
              community fled their homes to escape the mob. Police eventually
              intervened, firing into the air and wielding batons before
              dispersing the attackers with the help of Muslim clerics and
              elders. Authorities also said they have started launching raids in
              an effort to find all the perpetrators. Dozens of rioters were
              arrested.
            </p>

            <p
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
              }}
            >
              {" "}
              Police chief Bilal Mehmood told reporters they were also looking
              for Amir, who went into hiding to escape the mob, and would detain
              him to determine whether he had desecrated the Quran. Videos and
              photos posted on social media show an angry mob descending upon a
              church, throwing pieces of bricks and burning it. In another
              video, two other churches are attacked, their windows broken as
              attackers throw furniture out and set it on fire. Several
              policemen are seen in the videos watching the situation without
              intervening to stop the vandalism. In yet another video, a man is
              seen climbing to the roof of the church and removing the steel
              cross after repeatedly hitting it with a hammer as the crowd down
              on the road cheered him on.
            </p>

            <p
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
              }}
            >
              Khalid Mukhtar, a local priest, said most of the Christians living
              in the area had fled to safer places. “Even my house was burned,”
              he added. Mukhtar said there are 17 churches in Jaranwala and he
              believes most of them were attacked. The authorities did not
              immediately confirm that figure. Father Gulshan Barkat, who
              teaches church history at the National Catholic Institute of
              Theology in Karachi, described the blasphemy allegations as a
              “false accusation” and said the local mosques were also to blame
              because loudspeakers erected on minarets had earlier in the day
              called on Muslims to gather and “attack the churches and Christian
              community.”
            </p>
          </div>
        </div>
        {/* // FIRST  GALLERY */}
        <Container>
          <div>
            <div className="gallery">
              {photos2.map((photo) => (
                <div key={photo.id} className="photo">
                  <img
                    src={photo.src}
                    alt="Sam Sahotra Foundation"
                    loading="lazy"
                    height={""}
                    width={""}
                    onClick={() => handlePhotoClick(photo)}
                  />
                </div>
              ))}
            </div>
            {selectedPhoto && (
              <div className="selected-photo">
                <h2>{selectedPhoto.title}</h2>
                <img src={selectedPhoto.src} alt={selectedPhoto.title} />
              </div>
            )}
          </div>
        </Container>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Jaranwala;
