import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Container, Row, Col } from "react-bootstrap";
import videoBg from "../assetss/images/shapes/video-bg-1-1.png";
import videoImage from "../assetss/images/resources/SSF 1.png";
import "../css/Video.css"
const VideoCardTwo = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <section className="video-card-two">
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="L61p2uyiMSo"
        onClose={() => setOpen(false)}
      />
      <Container>
        <div
          className="inner-container"
          style={{ backgroundImage: `url(${videoBg})` }}
        >
          
          <Row className="align-items-center ">
            <Col lg={3}>
              <div className="video-card-two__box">
                <img src={videoImage} alt="Sam Sahotra Foundation" loading="lazy" />
                
              </div>
            </Col>
            <Col lg={4}>
              <h3>Sam Sahotra Foundation</h3>
            </Col>
            <Col lg={5}>
              <p>
              Sam Sahotra Foundation is a 501(c)(3) tax-exempt charity.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default VideoCardTwo;
