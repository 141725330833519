// Init
import React, { Fragment, useState, useEffect } from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";
// Component
export default function Header({ isDarkMode }) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  useEffect(() => {
    const handleScroll = () => {
      const isMobile = window.innerWidth <= 767; // Adjust the breakpoint as needed,
      const isLastSection =
        isMobile &&
        window.innerHeight + window.scrollY >= document.body.offsetHeight;

      if (navbarOpen && !isLastSection) {
        setNavbarOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navbarOpen]);

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <Fragment>
      <div className="main-navbar ">
        <nav
          className="navbar   navbar-expand-lg navbar-light bg-light"
          fixed="top"
        >
          <div className="container  cccccc ">
            <a className=" col-lg-4 col-7 logo-div " href="/">
              <img
                src={logo}
                className="img-fluid navbar-brand  nav-logo nav-logo-mobile"
                alt="Sam Sahotra Foundation"
                loading="eiger"
                title="Together We Make a Difference"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse   col-6 pre_ul navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav   ">
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/"
                  onClick={scrollTop}
                >
                  <li className="nav-item">
                    <a
                      className="nav-link   active"
                      aria-current="page"
                      href="#"
                    >
                      Home
                    </a>
                  </li>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/our-mission"
                  onClick={scrollTop}
                >
                  <li className="nav-item ">
                    <a className="nav-link" href="#">
                      Our Mission
                    </a>
                  </li>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/about-us"
                  onClick={scrollTop}
                >
                  <li className="nav-item ">
                    <a className="nav-link  " href="#">
                      About Us
                    </a>
                  </li>
                </Link>

                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/join"
                  onClick={scrollTop}
                >
                  <li className="nav-item ">
                    <a className="nav-link  " href="#">
                      Join SSF
                    </a>
                  </li>
                </Link>

                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/contact-us"
                  onClick={scrollTop}
                >
                  <li className="nav-item ">
                    <a className="nav-link  " href="#">
                      Contact Us
                    </a>
                  </li>
                </Link>

                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to="/news-and-update"
                  onClick={scrollTop}
                >
                  <li className="nav-item ">
                    <a className="nav-link  " href="">
                      News
                    </a>
                  </li>
                </Link>
              </ul>

              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/give/samsahotra"
                onClick={scrollTop}
              >
                <div className=" join-ssf-btn  col-lg-2 col-5">
                  <Button>GIVE</Button>
                </div>
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </Fragment>
  );
}
