import React, { useState } from "react";
import { Fragment } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import local from "../../assets/img/Contact-Us/Vector (22).svg";
import pho from "../../assets/img/Contact-Us/Frame.svg";
import icon from "../../assets/img/Contact-Us/Vector (23).svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HashLoader from "react-spinners/ClipLoader";

function GetInTouch() {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setComment] = useState("");

  const HandleSubmit = () => {
    setLoading(false);
    fetch(`${process.env.REACT_APP_SERVER_URL}/ssfcontact`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        subject,
        message,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (!data.success) {
          setLoading(true);

          toast.error(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          setLoading(true);

          toast.success(data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });

    // if(!name || !email || !phone || !subject || !comment )
    // {
    //   toast.error('please fill all fileds', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",,
    //     });
    // }
    // else
    // {
    //   alert('happy ending')
    // }
  };

  return (
    <Fragment>
      <Container fluid className="get-in-touch-main">
        <Row>
          <Col md={7} className=" d-flex justify-content-center">
            <Col md={9} className=" Get-In-Touch  ">
              <h2 className="pt-5 mt-4">Get In Touch With Us</h2>
              <div className="">
                <Form className="input-main">
                  <Row className="mb-2 d-flex justify-content-center">
                    <Col md={5} className="mb-4">
                      <Form.Control
                        type="name"
                        onChange={(e) => setName(e.target.value)}
                        className="Form-Control"
                        placeholder="Your Name"
                      />
                    </Col>
                    <Col md={5} className="mb-4">
                      <Form.Control
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="Form-Control"
                        placeholder="Email"
                      />
                    </Col>

                    <Col md={5} className="mb-4">
                      <Form.Control
                        type="number"
                        onChange={(e) => setPhone(e.target.value)}
                        className="Form-Control"
                        placeholder="Phone"
                      />
                    </Col>
                    <Col md={5} className="mb-4">
                      <Form.Control
                        type="subject"
                        onChange={(e) => setSubject(e.target.value)}
                        className="Form-Control"
                        placeholder="Subject"
                      />
                    </Col>
                    <Col md={10} className=" mt-4 ">
                      <Form.Control
                        as="textarea"
                        onChange={(e) => setComment(e.target.value)}
                        rows={4}
                        className="mb-4 Form-Controll"
                        placeholder="Any Comment"
                      />
                    </Col>
                  </Row>
                </Form>
              </div>

              <Container className="mt-4 mb-4 d-flex justify-content-center contact-submite-btn">
                <button onClick={HandleSubmit}>
                  {" "}
                  {loading ? (
                    "Send Message"
                  ) : (
                    <>
                      <HashLoader
                        color="white"
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />{" "}
                    </>
                  )}{" "}
                </button>
              </Container>
            </Col>
          </Col>
          <Col md={5} className="contact-side-bar">
            <Col md={7} className=" content-side-bar">
              <Col className="main-contact-centent">
                <div className=" visit-office d-flex">
                  <div className="location-icon">
                    <img
                      src={local}
                      alt="sam-sahotra-foundation"
                      loading="lazy"
                    />
                  </div>
                  <h4>Visit Office</h4>
                </div>
                <p>
                  9300 Forest Point Cir Suite 165, Manassas, VA 20110, United
                  States
                </p>
                <div className="hr-box">
                  <div className="hr-line2"></div>
                </div>
              </Col>

              <Col className="main-contact-centent">
                <div className=" visit-office d-flex">
                  <div className="location-icon">
                    <img
                      src={pho}
                      alt="sam-sahotra-foundation"
                      loading="lazy"
                    />
                  </div>
                  <h4>Phone</h4>
                </div>
                <p> +1 (703) 665-9000</p>

                <div className="hr-box">
                  <div className="hr-line2"></div>
                </div>
              </Col>

              <Col className="main-contact-centent">
                <div className=" visit-office d-flex">
                  <div className="email-icon">
                    <img
                      src={icon}
                      alt="sam-sahotra-foundation"
                      loading="lazy"
                    />
                  </div>
                  <h4>Email</h4>
                </div>
                <p>info@samsahotra.org</p>
              </Col>
            </Col>
          </Col>
        </Row>
        {/* ====================iframe map ================== */}
      </Container>

      <ToastContainer />
    </Fragment>
  );
}

export default GetInTouch;
