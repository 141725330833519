import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import { Fragment } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
// import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos1 } from "./photo1";
import { photos2 } from "./photo2";
import Val from "../../components/Our-Mission/volunter2";
import Footer from "../../components/Footer";

function Quenching() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const [currentImage2, setCurrentImage2] = useState(0);
  const [viewerIsOpen2, setViewerIsOpen2] = useState(false);

  const openLightbox2 = useCallback((event, { photo, index }) => {
    setCurrentImage2(index);
    setViewerIsOpen2(true);
  }, []);

  const closeLightbox2 = () => {
    setCurrentImage2(0);
    setViewerIsOpen2(false);
  };
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };
  return (
    <>
      <div>
        <Header />
        <Container fluid className="header-mission-picc_jer1 "></Container>
        <div className=" mt-3 mb-5">
          <div className="container Success mb-5">
            <h2 className="pb-3">
              SSF Distributes Water Coolers to Alleviate Struggles in Khand Pind
              Village, Lahore{" "}
            </h2>
            <div className="row">
              <div className="col-8">
                <p
                  style={{
                    fontSize: "23px",
                    textAlign: "justify",
                    lineHeight: "40px",
                  }}
                >
                  In a positive display of kindness and community spirit, the
                  SSF dedicated team undertook an idea to bring relief to the
                  poor residents of Khand village near the surroundings of
                  Lahore. As the burning sun beat down on the town, the struggle
                  to access clean drinking water became an increasingly pressing
                  concern. Recognizing the critical need for a sustainable
                  solution, the SSF Generous team stepped in to provide a source
                  of relief: water coolers.
                </p>
              </div>
              <div className="col-4">
                <Val />
              </div>
            </div>

            <p
              className="mt-4"
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
              }}
            >
              The Khand village had been struggling with a lack of access to
              basic facilities for far too long. Families, loaded by financial
              hardships, found it challenging to meet their daily needs,
              especially during the sweating summer months when hydration was of
              utmost importance.
            </p>

            <p
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
              }}
            >
              {" "}
              In response to this challenging situation, the dedicated
              volunteers of the SSF developed a well-coordinated relief effort.
              The distribution event was an encouraging scene; villagers came
              from all corners to witness the progress of this noble effort.
              Under the shade of a covering, volunteers dressed in matching
              T-shirts thoroughly set up the water coolers, each enhanced with a
              warm message of hope and unity. The villagers eagerly grouped
              around, their faces lighting up with thanks and expectation.
            </p>

            <p
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
              }}
            >
              The event was not just about distributing water coolers; it
              symbolized unity, understanding, and the belief that small acts of
              kindness could make a significant difference in the lives of those
              in need. They also organized educational sessions on proper
              hygiene and the importance of water conservation, ensuring that
              the impact of their assistance was sustainable and far-reaching.
            </p>
            <p
              style={{
                fontSize: "23px",
                textAlign: "justify",
                lineHeight: "40px",
              }}
            >
              The water coolers became more than just sources of hydration; they
              became symbols of hope and a testament to the power of compassion.
              The SSF's dedication to improving the lives of the less fortunate
              served as an inspiration to all, reminding us that a helping hand
              offered with sympathy can bring about profound positive change.
            </p>
          </div>
        </div>

        {/* // FIRST GALLERY */}
        <Container>
          <div>
            <div className="gallery">
              {photos2.map((photo) => (
                <div key={photo.id} className="photo">
                  <img
                    src={photo.src}
                    alt={photo.title}
                    onClick={() => handlePhotoClick(photo)}
                  />
                </div>
              ))}
            </div>
            {selectedPhoto && (
              <div className="selected-photo">
                <h2>{selectedPhoto.title}</h2>
                <img src={selectedPhoto.src} alt={selectedPhoto.title} />
              </div>
            )}
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Quenching;
