import React, { Fragment } from "react";
import Ourstory from "../assets/img/New_Header_Pic/ourstory.png";
import OurstoryCard from "../assets/img/New_Header_Pic/ourmission.png";
function OurStory() {
  console.log("checking...... checking");
  return (
    <Fragment>
      <div className="container-fluid w-100 p-0 mt-5">
        <div className="card ">
          <div className="row g-0">
            <div className="col-md-6">
              <iframe
                width="100%"
                height="415"
                src="https://www.youtube.com/embed/xAJtoP4e0Y4?si=ij_oYzpqWlIixLOg"
                title="YouTube Video"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
            <div className=" col-md-6 main-cc our-story-right-top">
              <div className="contentt-cc col-11">
                <h2>Our Story</h2>
                <p>
                  We support causes such as education, healthcare, and poverty
                  alleviation. Its founder, Sam Sahotra, is dedicated to making
                  a positive impact on individuals and communities worldwide
                  through philanthropy.
                </p>
              </div>
            </div>
          </div>
          <div className="row g-0 flex-wrap-reverse">
            <div className="col-md-6 main-cc our-story-right-top">
              <div className="contentt-cc col-11">
                <h2>Our Mission</h2>
                <p>
                  To realize its vision, the Sam Sahotra Non-Profit Foundation
                  has set forth a mission that encompasses various critical
                  areas.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <iframe
                width="100%"
                height="415"
                src="https://www.youtube.com/embed/816xpOQ5kLI?si=tG8sBnDoQjD-QFlw"
                title="YouTube Video"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default OurStory;
