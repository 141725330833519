import React from "react";
import { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import pic5 from "../assets/img/jer/WhatsApp Image 2023-08-20 at 5.31.03 AM.jpeg"
import { Link } from "react-router-dom";

function Cause() {
  return (
    <Fragment>
      <Container fluid className="">
        <Container>
          <div className="cause-supo mt-5 pt-5">
            <h2 className="Support">Support Your Cause</h2>
          </div>
          <Row className="cause-ca mt-5">
            <Col md={3} className="update-card ">
              <div className="donate-img2-2"></div>

              <div class="card-content pt-3 donate-btn  content-bg-color ">
                <h4> Mission in Toba Tek Singh Village </h4>

                <p className="p-2">
                  Toba Tek Singh village near the surroundings of Lahore has
                  been dealing with a lot of poverty and not having enough
                  necessities like groceries.
                </p>

                <a target="blank" href="/give/samsahotra">
                  <button className="mt-2">Give Now </button>
                </a>
                <div className="pt-4"></div>
              </div>
            </Col>
            <Col md={3} className=" update-card ">
              <div className="donate-img2"></div>

              <div class="card-content pt-3 donate-btn  content-bg-color ">
                <h4> Community Rebuilding</h4>

                <p>
                  Empowering Discriminated and destroyed communities through
                  support and a holistic approach to their rehabilitation.
                </p>
                <div className=" d-flex justify-content-between"></div>
                <div className=" d-flex justify-content-between">
                  <div class="progress  col-9 mt-1">
                    <div
                      id="slider"
                      class="progress-bar w-50 pt-2"
                      role="progressbar"
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div className="col-2">
                    <h5>39%</h5>
                  </div>
                </div>
                <Link to="/give/samsahotra">
                  <button className="mt-2">Give Now </button>
                </Link>
                <div className="pt-4"></div>
              </div>
            </Col>

            <Col md={3} className=" update-card ">
              <div className="donate-img">
                <button className="img-btn">Education</button>
              </div>

              <div class="card-content pt-3 donate-btn content-bg-color ">
                <h4>Education Program </h4>
                <p>
                  On the second visit to Khand village, the dedicated volunteers
                  of SSF helped an orphan girl. They helped her by giving her a
                  school bag.
                </p>
                <div className="d-flex justify-content-between">
                  <div class="progress  col-9 mt-1">
                    <div
                      id="slider"
                      class="progress-bar w-50 pt-2"
                      role="progressbar"
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div className="col-2">
                    <h5>45%</h5>
                  </div>
                </div>
                <Link to="/give/samsahotra">
                  <button className="donate-btn mt-2">Give Now </button>
                </Link>
                <div className="pt-4"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>
  );
}

export default Cause;
